import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_FAILED,
    FORGET_PASSWORD_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
} from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string): AuthAction => ({
    type: LOGIN_USER,
    payload: { username, password },
});

export const loginUserSuccess = (): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: {}
});

export const loginUserFailed = (error: Response): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname: string, email: string, password: string): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, password },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error: Response): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: {history},
});

export const logoutUserSuccess = (): AuthAction => ({
    type: LOGOUT_USER_SUCCESS,
    payload: {},
});

export const forgetPassword = (email: string): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: {email},
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const changePassword = (password: string, token: string): AuthAction => ({
    type: CHANGE_PASSWORD,
    payload: {password, token},
})

export const changePasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const changePasswordFailed = (error: Response): AuthAction => ({
    type: CHANGE_PASSWORD_FAILED,
    payload: error,
});
