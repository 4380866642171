/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, { RefAttributes } from 'react';
import ReactSelect, { GroupBase, Props, SelectInstance} from 'react-select';
import { colors } from '../constants/colors';

const MENULIST_GROW_ITEM_HEIGHT = 50; // px
const MENULIST_GROW_MAX_HEIGHT = 700; // px
const MENULIST_GROW_ON_NUM_ITEMS = 6 // items

export const customStyles = (width?: string, height?: string) => ({
    control: (base: any) => ({
        ...base,
        '&:hover': { borderColor: 'gray' },
        border: '1px solid lightgray',
        boxShadow: 'none',
        'height': height,
        'minHeight': height,
    }),
    menu: (base: any) => ({
        ...base,
        'z-index': 10,
    }),
    container: (base: any) => ({
        ...base,
        'flex': 1,
        'minWidth': width,
        'maxWidth': width,
    }),
    option: (base: any) => ({
        ...base,
        borderBottom: '1px solid white'
    }),
    groupHeading: (base: any, element: any) => ({
        ...base,
        backgroundColor: element.theme.colors.neutral10
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
    }),
});

const customTheme = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: colors['action-blue'],
    }
});

interface CustomProps {
    width?: string
    height?: string
}

const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    ref,
    width,
    height,
    options,
    ...rest
}: Props<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>> & CustomProps) => {
    const numItems = options?.reduce((acc: number, item: any) => acc + (item.options?.length ?? 1), 0) ?? 0

    return <ReactSelect
        {...rest}
        ref={ref}
        styles={customStyles(width, height)}
        theme={customTheme}
        options={options}

        // When the menulist has a large number of items, grow it by MENULIST_GROW_ITEM_HEIGHT px per item.
        {...((numItems && numItems > MENULIST_GROW_ON_NUM_ITEMS) && {
            'maxMenuHeight': Math.min(MENULIST_GROW_ITEM_HEIGHT * numItems, MENULIST_GROW_MAX_HEIGHT)
        })}

    />
}

export default Select
