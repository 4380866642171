// @flow

import { combineReducers } from 'redux';
import AppMenu from './appMenu/reducers';
import Auth from './auth/reducers';
import Config from './config/reducers';
import Layout from './layout/reducers';
import User from './user/reducers';

export default combineReducers({
    AppMenu,
    Auth,
    Config,
    Layout,
    User,
});
