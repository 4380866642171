/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import moment from 'moment'
import {useState, useCallback} from 'react'
import {
    EventCategory,
    EventType,
    GraphReportRequestOptions,
    GraphReportResponseOptions,
    ReportPeriod,
    Variable, VariableDataType
} from '../constants/api'
import {Options} from '../components/GraphCreatorModal';
import {DurationUnit, stringToTimeDuration, TimeDuration} from '../components/reports/graph/AdditionalOptions';

export type EventCategoryTypes = (EventCategory & {types: EventType[]})[]

/**
 * Group a list of event type objects by their category value, grouping in a 
 * "No Category" category without an ID if an event type's category is undefined.
 * @param eventTypes List of event type objects.
 * @returns List of categories.
 */
export const groupByCategory = (eventTypes: EventType[]): EventCategoryTypes => {
    const noCategory: EventCategory = {
        id: 0,
        name: 'No Category',
        types: []
    }

    const categories = eventTypes.reduce(function (categoryList: any[], type) {
        let categoryIndex = categoryList.findIndex((category: any) => category?.name === type.category?.name)
        if (categoryIndex === -1) {
            if (type.category) {
                categoryList.push({
                    ...type.category,
                    name: type.category.name,
                    types: [type]
                })
            } else {
                noCategory.types.push(type)
            }
        } else {
            categoryList[categoryIndex].types.push(type)
        }
        return categoryList
    }, [])
    categories.push(noCategory)
    return categories
}

/**
 * Returns an object containing the elements from the items array, indexed by the key returned from getKey
 * function applied to each element.
 * If any two elements have the same key returned by getKey, the last one gets added to the object.
 * Equivalent to Kotlin's associateBy function.
 * Discards any items where getKey returns undefined.
 * @param items Source array of items.
 * @param getKey Function returning the key to group by.
 */
export function associateBy<T>(items: T[], getKey: (item: T) => any): {[key: string]: T} {
    return items.reduce((acc: { [key: string]: T }, item) => {
        const key = getKey(item)
        if (key !== undefined) {
            acc[key.toString()] = item
        }
        return acc
    }, {})
}

/**
 * Returns all distinct objects from the given list as determined by the string value of the given key.
 * If multiple elements have the same value, only the last one is included in the returned array.
 * @param items Source array of objects.
 * @param key Key for each item whose value determines an object as distinct.
 */
export function distinctValuesByKey<T extends object>(items: T[], key: keyof T): T[] {
    return Object.values(associateBy(items, (it) => it[key]))
}

/**
 * Returns all distinct objects from the given list as determined by the value of its ID.
 * If multiple elements have the same ID, only the last one is included in the returned array.
 * @param items Source array of objects with `id` properties.
 */
export function distinctById<T extends {id: number}>(items: T[]): T[] {
    return distinctValuesByKey(items, 'id')
}

/**
 * Groups elements of the original array by the key returned by the given getKey function applied to each
 * element and returns an object where each group key is associated with an array of corresponding elements.
 * Discards any items where getKey returns undefined.
 * @param items Source array of items.
 * @param getKey Function returning the key to group by.
 */
export function groupBy<T>(items: T[], getKey: (item: T) => any): {[key: string]: T[]} {
    return items.reduce((acc: { [key: string]: T[] }, item) => {
        const key = getKey(item)
        if (key !== undefined) {
            if (acc[key] === undefined) {
                acc[key] = [item]
            } else {
                acc[key].push(item)
            }
        }
        return acc
    }, {})
}

/**
 * Filters an array of objects to only contain unique values based on a given property name.
 *
 * @template T - The type of objects in the array.
 * @param {T[]} array - The array of objects to filter.
 * @param {keyof T} propertyName - The name of the property to use for filtering.
 * @returns {T[]} - An array of the unique objects from the input array based on the property value.
 */
export function filterUniqueByProperty<T>(
    array: T[],
    propertyName: keyof T
): T[] {
    const uniqueValues = new Set();
    const filteredArray: T[] = [];

    for (const value of array) {
        const propertyValue = value[propertyName];

        if (!uniqueValues.has(propertyValue)) {
            uniqueValues.add(propertyValue);
            filteredArray.push(value);
        }
    }

    return filteredArray;
}

/**
 * Partitions an array into two arrays depending on if each element passes or fails the given predicate.
 * @param array Array to partition into two arrays.
 * @param predicate Function called with each element as its argument.
 * @returns Tuple of two T[], with the first one containing all elements that passed the predicate and the second
 * being all elements that failed the predicate.
 */
export function partition<T>(array: Array<T>, predicate: (element: T) => boolean) {
    return array.reduce(([pass, fail]: T[][], element: T) => {
        return predicate(element) ? [[...pass, element], fail] : [pass, [...fail, element]]
    }, [[],[]])
}

/**
 * Formats a moment.Duration object as a string in a format like "#d #h #m #s" excluding zeroed values.
 * @param duration moment.Duration object to format.
 * @returns Formatted string represneting duration.
 */
export function formatDurationAsDHMS(duration: moment.Duration) {
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()
    return ((days > 0 ? `${days}d ` : '')
        + (hours > 0 ? `${hours}h ` : '')
        + (minutes > 0 ? `${minutes}m ` : '')
        + (seconds > 0 ? `${seconds}s ` : '')).trim()
}

/** Format the given number of seconds as a duration of hh:mm:ss */
export function formatSecondsAsHHMMSS(seconds: number) {
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return [`${hours}`.padStart(2, '0'), `${minutes}`.padStart(2, '0'), `${seconds}`.padStart(2, '0')].join(':')
}

export enum TimeUnit {
    d = 'days', h = 'hours', m = 'minutes', s = 'seconds'
}

// Match a string that is one or more 1dp float/integers > 0 suffixed with a d, h, m, or s.
const DURATION_STRING_REGEX = /^(\s*(?=.*[1-9])\d*(?:\.\d)?[dhms]\s*)+$/
// Match a 1dp float/integer > 0 suffixed with a d, h, m, or s
const DURATION_PART_REGEX = /(?=.*[1-9])\d*(?:\.\d)?[dhms]/g

/** Return a human friendly description using the given report period options. */
export const getReportPeriodDescription = (
    reportPeriod?: ReportPeriod,
    showLastDuration?: string,
    showUntilLast?: string,
    limitByStartOfDay?: boolean
): string => {
    /** Get the name of the time unit used in the duration, plural or singular based on the value. */
    function getTimeUnitName(timeDuration: TimeDuration): string {
        let unitName = TimeUnit[timeDuration.unit as keyof typeof TimeUnit] as string
        if (timeDuration.value === 1) {
            unitName = unitName.slice(0, -1) // Remove plural s suffix
        }
        return unitName
    }

    // Fixed date to date report
    if (reportPeriod) {
        const {start_date, end_date} = reportPeriod
        return `${new Date(start_date).toLocaleDateString()} - ${new Date(end_date).toLocaleDateString()}`
    }
    // Last x hours/minutes etc.
    if (showLastDuration) {
        const lastDuration = stringToTimeDuration(showLastDuration)!!
        let durationDescription = `${lastDuration.value} ${getTimeUnitName(lastDuration)}`

        // Fixed offset date range e.g. 2 days ago to 1 day ago.
        if (showUntilLast) {
            const untilDuration = stringToTimeDuration(showUntilLast)!!
            // Special case for "Yesterday"
            if (limitByStartOfDay
                && lastDuration.unit === DurationUnit.Days
                && untilDuration.unit === DurationUnit.Days
                && lastDuration.value === 2
                && untilDuration.value === 1
            ) {
                return 'Yesterday'
            }
            return `${durationDescription} ago until ${untilDuration.value} ${getTimeUnitName(untilDuration)} ago`
        } else if (limitByStartOfDay
            && lastDuration.value === 1
            && lastDuration.unit === DurationUnit.Days
        ) {
            return 'Today'
        } else {
            return `Last ${durationDescription}`
        }
    }
    return ''
}

/** Return a human friendly description of a Graph Report's configured report period options. */
export const getReportPeriodDescriptionFromOptions = (options: GraphReportResponseOptions): string =>
    getReportPeriodDescription(
        options.report_period,
        options.show_last_duration,
        options.show_until_last,
        options.limit_by_start_of_day
    )

/**
 * Tries to parse a string representing a duration in days, hours, minutes and seconds, into a moment.Duration object.
 * @param duration A string in a format like "#d #h #m # s".
 * @return A moment.Duration object representing the duration, or null if the string could not be parsed.
 */
export function parseDHMSAsDuration(duration: string): moment.Duration | null {
    const matches = duration.match(DURATION_STRING_REGEX) !== null
    if (matches) {
        const parts = duration.match(DURATION_PART_REGEX)
        const unitEntries = Object.fromEntries(parts!!.map(it => [
            TimeUnit[it.slice(it.length - 1) as keyof typeof TimeUnit],
            Number(it.slice(0, it.length - 1))
        ]))
        return moment.duration(unitEntries)
    }
    return null
}

/**
* If the default value is not undefined, return it inside a resolved promise.
* Otherwise, execute the promiseCreator function.
* @param promiseCreator A function that returns a promise.
* @param defaultValue A default value or undefined.
*/
export function defaultValueOrPromise<T>(promiseCreator: () => Promise<T>, defaultValue?: T): Promise<T> {
    if (defaultValue === undefined) {
        return promiseCreator()
    } else {
        return Promise.resolve(defaultValue)
    }
}

/**
 * Sorts the objects in items by the order their IDs appear in the idsOrder array.
 * @param items An array of objects which have a numeric id property.
 * @param idsOrder The order to sort the items into.
 */
export function sortObjectArrayById<T extends {id: number}>(items: T[], idsOrder: number[]): T[] {
    const byId = associateBy(items, (it) => it.id)
    return idsOrder.map((id) => byId[id])
}

type ClassStateAction<S> = Partial<S> | ((prevState: S) => Partial<S>)

/**
 * A wrapper around the setState hook that preserves class component partial update setState behaviour,
 * for the purpose of making it easier to refactor class components into functional components.
 * @param initialState
 */
export function useClassState<S>(initialState: S | (() => S)): [S, ((stateUpdate: ClassStateAction<S>) => void)] {
    const [state, __setState] = useState<S>(initialState)

    const setState = useCallback(
        (stateUpdate: ClassStateAction<S>) => {
            __setState(prevState => ({
                ...prevState,
                ...(typeof stateUpdate === 'function' ? stateUpdate(prevState) : stateUpdate)
            }))
        }, [__setState]
    )

    return [state, setState]
}

/**
 * Return if the given list of Graph Report Options includes the required Options.
 * @param opts List of options to check.
 * @param reqOpts List of options to check for.
 * @param all If `true`, all the options in opts have to be present.
 */
export function acceptsOptions(
    opts: (keyof GraphReportRequestOptions)[],
    reqOpts: (keyof Options)[],
    all: boolean = false,
) {
    return !all
        ? reqOpts.some(option => opts.includes(option))
        : reqOpts.every(option => opts.includes(option))
}

/**
 * Function to see whether a string contains a substring, ignoring case.
 * @param string the string to see whether it contains a substring.
 * @param substring the substring to see if a string includes.
 * @return whether the string includes a substring, ignoring case.
 */
export function includesIgnoreCase(string: string, substring: string) {
    return string.toLowerCase().includes(substring.toLowerCase())
}

/** Return the value from the correct value field, based on the variable's type */
export const getVariableValue = (variable: Variable) => {
    switch (variable.type) {
        case 'NUMERIC':
            return variable.numeric_value
        case 'TEXT':
            return variable.text_value
        case 'DATETIME':
            return moment(variable.datetime_value).format('YYYY-MM-DDTHH:mm:ss')
        case 'TIME':
            return variable.time_value
        case 'DURATION':
            return variable.duration_value
    }
}

/** Return the name of the field where the value of a variable of a given type is stored. */
export const getVariableValueFieldName = (type: VariableDataType): keyof Variable => {
    switch (type) {
        case 'NUMERIC':
            return 'numeric_value'
        case 'TEXT':
            return 'text_value'
        case 'DATETIME':
            return 'datetime_value'
        case 'TIME':
            return 'time_value'
        case 'DURATION':
            return 'duration_value'
    }
}

/**
 * Returns if the current time overlaps with a given time range.
 * @param startTime: the starting time of the range.
 * @param endTime: the ending time of the range. If null, open range.
 * **/
export const isTimeRangeActive = (startTime: string, endTime?: string): boolean => {
    let startTimeMoment = moment(startTime)
    let endTimeMoment = endTime ? moment(endTime) : undefined
    if (!startTimeMoment) {
        return false
    }
    let now = moment()
    if (!endTimeMoment) {
        return now.isAfter(startTimeMoment)
    }
    return now.isBefore(moment(endTimeMoment)) && now.isAfter(startTimeMoment)
}

export function copyTextToClipboard(text: string) {
    return navigator.clipboard.writeText(text)
}
