import React from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {initConfig} from './redux/actions';
import {setupVersionRefresh} from './VersionRefresh';
import {ToastContainer} from 'react-toastify';
import Routes from './routes/Routes';

// Theme
import './assets/scss/Saas.scss';

setupVersionRefresh();
const store = configureStore()
store.dispatch(initConfig())

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ToastContainer/>
        <Routes/>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
