/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {getOrGoLogin, postOrGoLogin} from '../../helpers/api';
import {CHANGE_PASSWORD, FORGET_PASSWORD, LOGIN_USER, LOGOUT_USER, REGISTER_USER} from './constants';
import {API} from '../../constants/api';
import {PAGES} from '../../constants/pages';
import {
    changePasswordFailed,
    changePasswordSuccess,
    forgetPasswordFailed,
    forgetPasswordSuccess,
    loginUserFailed,
    loginUserSuccess,
    logoutUserSuccess,
    registerUserFailed,
    registerUserSuccess,
} from './actions';
import {setAuthenticated} from '../../helpers/auth';
import {handleErrorAsToast} from '../../helpers/errors';
import {toast} from 'react-toastify';

interface AuthPayload {payload: any}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({payload: {username, password}}: AuthPayload) {
    try {
        yield call(postOrGoLogin, API.LOGIN.path, {
            email: username, password: password
        }, 'LOGIN');
        setAuthenticated(true);
        yield put(loginUserSuccess());
    } catch (error: any) {
        yield put(loginUserFailed(error));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({payload: {history}}: AuthPayload) {
    try {
        yield call(getOrGoLogin, API.LOGOUT.path);
        setAuthenticated(false)
        yield call(() => {
            history.push(PAGES.ACCOUNTS.LOGIN.path);
        });
        yield put(logoutUserSuccess());
    } catch (error: any) {
        handleErrorAsToast(error)
    }
}

/**
 * Register the user
 */
function* register({payload: {fullname, email, password}}: AuthPayload) {
    const options = {
        body: JSON.stringify({fullname, email, password}),
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
    };

    try {
        const response: string = yield call(getOrGoLogin, '', options);
        yield put(registerUserSuccess(response));
    } catch (error: any) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({payload: {email}}: AuthPayload) {
    try {
        yield call(postOrGoLogin, API.FORGOT_PASSWORD.path, {email});
        yield put(forgetPasswordSuccess('Success - Please check your inbox'));
    } catch (error: any) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

function* changePassword({payload: {password, token}}: AuthPayload) {
    try {
        yield call(postOrGoLogin, API.FORGOT_PASSWORD.CHANGE_PASSWORD.path, {password, token})
        toast.success('Password successfully changed')
        yield put(changePasswordSuccess('Password successfully changed'));
    } catch (error: any) {
        let message;
        switch (error.status) {
            case 500:
                message = Promise.resolve('Internal Server Error');
                break;
            case 404:
                message = Promise.resolve('Invalid token');
                break;
            case 401:
                message = Promise.resolve('Invalid token');
                break;
            default:
                // Join error messages pertaining to invalid password reasons, such as length
                message = error.json().then((result: any) => result?.password?.join(' '));
        }
        yield put(changePasswordFailed(message));
    }
}

export function* watchLoginUser(): any {
    // @ts-ignore
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    // @ts-ignore
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
    // @ts-ignore
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
    // @ts-ignore
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchChangePassword(): any {
    // @ts-ignore
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchChangePassword)
    ]);
}

export default authSaga;
