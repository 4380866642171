import {setAuthenticated} from './auth'

function getCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
}

const SITE_KEY_RETRIES = 10

interface APIRoute<T> {
    path: string,
    response: T
}

/**
 * Tries to get a reCAPTCHA token using the site key from `globalThis`. Waits until the site key is
 * initialised, then returns a promise with the token or undefined depending on if the site key is null or not. 
 * If the site key is not null but is invalid, or is never initialised, return a promise with an error.
 * @param action Arbitrary string that defines interaction type for token.
 */
async function getGRecaptchaToken(action: string): Promise<string | undefined> {
    for(let i = 0; globalThis.gRecaptchaSiteKey === undefined && i < SITE_KEY_RETRIES; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000))
    }
    
    if (globalThis.gRecaptchaSiteKey === undefined) return Promise.reject('Timeout on reCAPTCHA site key.')

    return new Promise((resolve, reject) => {
        if (globalThis.gRecaptchaSiteKey !== null) {
            window.grecaptcha.enterprise.ready(async () => {
                try {
                    window.grecaptcha.enterprise.execute(
                        globalThis.gRecaptchaSiteKey!!, {action: action},
                    ).then(token => resolve(token))
                } catch(error) {
                    reject(error)
                }
            })
        } else {
            resolve(undefined)
        }
    })
}

/**
 * Get the suggested filename of the download from the content-disposition header.
 * @param contentDisposition content-disposition header string.
 */
const getDownloadFileName = (contentDisposition: string): string => {
    let filename = 'untitled';
    if (contentDisposition.includes('attachment')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename
}

/**
 * Download a file attachment from a request.
 * @param blob File contents.
 * @param fileName File name.
 */
const downloadFile = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.addEventListener('load', () => {
        URL.revokeObjectURL(url)
        document.body.removeChild(link)
    })
    link.click()
}

const parseResponse = (response: Response) => {
    const contentType = response.headers.get('content-type')
    const disposition = response.headers.get('content-disposition')
    if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json()
    } else if (disposition && disposition.includes('attachment')) {
        return response.blob()
            .then((blob) => downloadFile(blob, getDownloadFileName(disposition)))
    } else {
        return response.text()
    }
}

const getPromiseForGoLogin = <T>(url: string | APIRoute<T>, options: any = {}): Promise<T> => {
    const path = (typeof url === 'string') ? url : url.path
    return fetch(path, options)
        .then(response => {
            if (!response.ok) throw response;
            return parseResponse(response)
        })
        .catch(error => {
            if (error.status === 401) {
                setAuthenticated(false)
                window.location.reload()
            }
            throw error;
        });
}


/**
 * Get request (method=GET).
 * @param url The base name of the endpoint, with no query parameters.
 * @param fields An object of key-value pairs of parameter name to value, or a URLSearchParams object.
 */
export async function getOrGoLogin<T = any>(
    url: string | APIRoute<T>,
    fields?: URLSearchParams | {[key: string]: any},
    gRecaptchaAction?: string
): Promise<T> {
    let options: any = {}
    options['method'] = 'GET'
    options['headers'] = {
        'G-RECAPTCHA-TOKEN': gRecaptchaAction ? await getGRecaptchaToken(gRecaptchaAction) : undefined,
    }
    if (fields) {
        const path = (typeof url === 'string') ? url : url.path
        let params: URLSearchParams
        if (fields instanceof URLSearchParams) {
            params = fields
        } else {
            params = new URLSearchParams();
            Object.entries(fields).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    // URLSearchParams doesn't correctly parse lists, so manually append each element
                    for (let subValue of value) {
                        params.append(`${key}[]`, subValue);
                    }
                } else if (value !== undefined) {
                    params.append(key, value)
                }
            })
        }
        url = path + '?' + params;
    }

    return getPromiseForGoLogin(url, options)
}


/**
 * Post request (method=POST).
 */
export async function postOrGoLogin<T = any>(
    url: string | APIRoute<T>,
    json: any,
    gRecaptchaAction?: string
): Promise<T> {
    let options = {
        method: 'POST',
        headers: {
            'G-RECAPTCHA-TOKEN': gRecaptchaAction ? await getGRecaptchaToken(gRecaptchaAction) : undefined,
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(json)
    };

    return getPromiseForGoLogin(url, options)
}


/**
 * Put request (method=PUT).
 */
export async function putOrGoLogin<T = any>(
    url: string | APIRoute<T>,
    json: any,
    gRecaptchaAction?: string
): Promise<T> {
    let options = {
        method: 'PUT',
        headers: {
            'G-RECAPTCHA-TOKEN': gRecaptchaAction ? await getGRecaptchaToken(gRecaptchaAction) : undefined,
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(json)
    };

    return getPromiseForGoLogin(url, options)
}

/**
 * Patch request (method=PATCH).
 */
export async function patchOrGoLogin<T = any>(
    url: string | APIRoute<T>,
    json: any,
    gRecaptchaAction?: string
): Promise<T> {
    let options = {
        method: 'PATCH',
        headers: {
            'G-RECAPTCHA-TOKEN': gRecaptchaAction ? await getGRecaptchaToken(gRecaptchaAction) : undefined,
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(json)
    };

    return getPromiseForGoLogin(url, options)
}

/**
 * Delete request (method=DELETE).
 * NOTE: delete requests does not accept data in the body. Need to add param in the url.
 */
 export async function deleteOrGoLogin<T = any>(
     url: string | APIRoute<T>,
     fields?: any,
     gRecaptchaAction?: string
): Promise<T> {
    let options = {
        method: 'DELETE',
        headers: {
            'G-RECAPTCHA-TOKEN': gRecaptchaAction ? await getGRecaptchaToken(gRecaptchaAction) : undefined,
            'credentials': 'same-origin',
            'X-CSRFToken': getCookie('csrftoken'),
        },
    };
    if (fields) {
        const path = (typeof url === 'string') ? url : url.path
        url = path + '?' + new URLSearchParams(fields);
    }

    return getPromiseForGoLogin(url, options)
}

/**
 * Returns the resolved value of a PromiseSettledResult object if it has been fulfilled,
 * otherwise returns a default value.
 */
export function fulfilledOrDefault<T>(result:  PromiseSettledResult<T>, defaultValue: T) {
     if (result.status === 'fulfilled') {
         return result.value
     } else {
         return defaultValue
     }
}
