/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {getOrGoLogin} from '../../helpers/api';

import {API} from '../../constants/api';
import {
    initConfigFailed,
    initConfigSuccess,
} from './actions';
import {ConfigType} from './types';
import {INIT_CONFIG, INIT_CONFIG_SUCCESS} from './constants';
import {handleErrorAsToast} from '../../helpers/errors';
import {init} from '../../helpers/config';

function* initConfig() {
    try {
        const config: ConfigType = yield call(getOrGoLogin, API.CONFIG.path);
        init(config)
        yield put(initConfigSuccess(config));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to initialise client config')
        yield put(initConfigFailed(error.statusText));
    }
}

export function* watchConfigInit(): any {
    yield takeEvery(INIT_CONFIG, initConfig);
}

export function* watchConfigInitSuccess(): any {
    yield takeEvery(INIT_CONFIG_SUCCESS, initConfig);
}

function* userSaga(): any {
    yield all([fork(watchConfigInit)]);
}

export default userSaga;