/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    INIT_CONFIG,
    INIT_CONFIG_FAILED,
    INIT_CONFIG_SUCCESS,
} from './constants';
import {State, ConfigAction} from './types';

const INIT_STATE = {
    error: null,
    message: null
};

const Config = (state: State = INIT_STATE, action: ConfigAction) => {
    switch (action.type) {
        case INIT_CONFIG:
            return action.payload;
        case INIT_CONFIG_FAILED:
            return {...state, error: action.payload};
        case INIT_CONFIG_SUCCESS:
            return action.payload;
        default:
            return {...state};
    }
};

export default Config;