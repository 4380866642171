/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

export type OEEFactor = 'Availability' | 'Performance' | 'Quality'

export const APP_NAME = process.env.REACT_APP_TITLE || 'Insight'

export const TIMESTAMP_FORMAT = 'DD-MM-YY, HH:mm:ss'

/**
 * Options for the text size of reports on the dashboard.
 */
export enum ReportTextSize {
    NORMAL = 'normal',
    LARGE = 'large'
}
