/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Alert} from 'reactstrap'
import {PAGES} from '../constants/pages'
import ErrorPage from '../pages/error/ErrorPage'

const API_ERROR_TEXT_KEYS = ['msg', '__all__', 'detail']

const logError = (error: any, errorText: string, message?: string) => {
    if (message) {
        console.error(message + ':', errorText, error)
    } else {
        console.error(errorText, error)
    }
}

/**
 * Get a promise for a descriptive error text interpreted from a response's JSON body.
 * @param error Response to retrieve error text from.
 * @returns Promise resolving into an error text detailing the error that caused the request to fail.
 */
const getErrorTextFromJsonPromise = (error: Response): Promise<string> => {
    const getErrorTextFromBody = (body: any): string => {
        if (body) {
            if (typeof body === 'object') {
                if (Array.isArray(body)) {
                    // Get error text from first element if it exists.
                    if (body.length > 0) {
                        return getErrorTextFromBody(body[0])
                    }
                } else {
                    // Use first found error text key's value as error text.
                    for (let i = 0; i < API_ERROR_TEXT_KEYS.length; i++) {
                        const errorText = body[API_ERROR_TEXT_KEYS[i]]
                        if (errorText !== undefined) return String(errorText)
                    }
                    // If error is in form { field: ['error 1', 'error 2'] }, format it
                    return Object.entries(body).map(([key, value]) =>
                        Array.isArray(value) ? `${key}: ${value.join(' ')}` : `${key}: ${value}`
                    ).join(' ')
                }
            } else {
                try {
                    // Try to interpret body as string.
                    return String(body)
                    // eslint-disable-next-line no-empty
                } catch { }
            }
        }
        // Descriptive message cannot be interpreted from body.
        // As a last resort, throw error with stringified body as part of message.
        throw TypeError(`Cannot retrieve descriptive message from body: "${JSON.stringify(body)}"`)
    }

    return error.json()
        .then(body => getErrorTextFromBody(body))
        .catch((e: TypeError) => {
            console.warn(e.message)
            return error.statusText
        })
}

/**
 * Handles a given error of any type by extracting an error text string
 * from it, logging the error, and calling the given handler with the extracted string.
 * @param error Error to handle.
 * @param message Extra description to log with error.
 * @param handler Function that does something with the extracted string.
 */
export const handleError = (error: any, message?: string, handler?: (errorText: string) => void) => {
    if (error instanceof Response) {
        getErrorTextFromJsonPromise(error)
        .then((errorText) => {
            logError(error, errorText, message)
            handler && handler(errorText)
        })
    } else {
        logError(error, 'Error', message)
        handler && handler('Error')
    }
}

/**
 * Handles errors as a console error.
 */
export const handleErrorAsConsole = (error: any, message?: string) => {
    handleError(error, message)
}

/**
 * Handles errors as a toast message.
 */
export const handleErrorAsToast = (error: any, message?: string, delay?: number) => {
    handleError(error, message, (errorText: string) => {
        if (message) {
            toast.error(<>{message}: <strong>{errorText}</strong></>, {delay: delay})
        } else {
            toast.error(<strong>{errorText}</strong>, {delay: delay})
        }
    })
}

interface ErrorAlertProps {
    error: any,
    message?: string,
}

const ErrorAlert = ({error, message}: ErrorAlertProps) => {
    const [prevError, setPrevError] = useState<any>()
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        if (error !== prevError || !prevError) {
            setPrevError(error)
            handleError(error, message, setErrorText)
        }
    }, [error, prevError, message])

    return <Alert color='danger' isOpen={true}>
        <div>{(message ? `${message}: ` : '') + errorText}</div>
    </Alert>
}

/**
 * Return an element that handles errors as an alert.
 */
export const renderErrorAsAlert = (error: any, message?: string) => {
    return <ErrorAlert error={error} message={message}/>
}

/**
 * Return an element that handles errors as an error page.
 */
export const renderErrorAsPage = (error: any, message?: string, doRedirect: boolean = false) => {
    return doRedirect ? <Redirect to={{
        pathname: PAGES.ERROR_PAGE.path,
        state: {
            error: error,
            message: message,
            didRedirect: true,
        }
    }}/> : <ErrorPage
        error={error}
        message={message}
    />
};
