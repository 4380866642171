import React, {Suspense} from 'react';
import {connect} from 'react-redux';
import {Container} from 'reactstrap';

const loading = () => <div className='text-center'/>;

type DefaultLayoutProps = {
    children?: any,
};

function FullScreenLayout({children}: DefaultLayoutProps) {
    return (
        <div className='app'>
            <div className='wrapper'>
                <div className='content'>
                    <Container fluid>
                        <Suspense fallback={loading()}>{children}</Suspense>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default connect()(FullScreenLayout);
