/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

interface DeleteModalProps {
    isOpen: boolean,
    toggle: () => void,
    details?: {
        type?: string,
        name?: string,
        msg?: string,
    },
    onDelete: () => void,
    children?: React.ReactNode
}

export function DeleteModal(props: DeleteModalProps) {
    return (
        <ConfirmationModal
            {...props}
            action={'Delete'}
            onAction={props.onDelete}
        />
    )
}

interface ConfirmationModalProps {
    isOpen: boolean,
    toggle: () => void,
    details?: {
        type?: string,
        name?: string,
    },
    action: string,
    onAction: () => void,
    colorClass?: string,
    children?: React.ReactNode
}


export function ConfirmationModal(props: ConfirmationModalProps) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle} className={`modal-colored-header bg-${props.colorClass || 'danger'}`}>
                {props.details?.type && `${props.action} ${props.details.type}`}
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to {props.action.toLowerCase()}
                    {props.details?.name ? <b> {props.details.name}</b> : ' this'}
                    ?</p>
                {props.children}
            </ModalBody>
            <ModalFooter>
                <Button color={props.colorClass || 'danger'} onClick={() => {
                    props.onAction();
                    props.toggle();
                }}>{props.action}</Button>
                <Button color='secondary' onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}