/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing'
import {ConfigType} from '../redux/config/types';

declare global {
    var gRecaptchaSiteKey: string | null | undefined
}

export function init(config: ConfigType) {
    // Initialise GRecaptcha
    if (config.gRecaptcha.siteKey !== null) {
        const tag = document.createElement('script')
        tag.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${config.gRecaptcha.siteKey}`
        tag.async = true
        tag.defer = true
        document.head.appendChild(tag)

        globalThis.gRecaptchaSiteKey = config.gRecaptcha.siteKey
    } else {
        globalThis.gRecaptchaSiteKey = null
    }

    // Initialise Sentry
    Sentry.init({
        dsn: config.sentry.enabled ?
            'https://166baf87d4d24cfe81abdceb87b675a6@o444983.ingest.sentry.io/5556934' : '',
        environment: config.sentry.environment,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        sampleRate: 1.0,
        tracesSampleRate: 1.0,
    })
}