/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
export const INIT_USER_INFO = 'INIT_USER_INFO';
export const INIT_USER_INFO_SUCCESS = 'INIT_USER_INFO_SUCCESS';
export const INIT_USER_INFO_FAILED = 'INIT_USER_INFO_FAILED';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAILED = 'USER_UPDATE_PASSWORD_FAILED';
export const USER_UPDATE_DETAILS = 'USER_UPDATE_DETAILS';
export const USER_UPDATE_DETAILS_SUCCESS = 'USER_UPDATE_DETAILS_SUCCESS';
export const USER_UPDATE_DETAILS_FAILED = 'USER_UPDATE_DETAILS_FAILED';
