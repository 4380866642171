import React, {Component} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {allFlattenRoutes as routes} from './index';
import {isUserAuthenticated} from '../helpers/auth';

// All layouts/containers
import AuthLayout from '../layouts/Auth';
import HorizontalLayout from '../layouts/Horizontal';
import FullScreenLayout from '../layouts/FullScreen';
import {FULL_SCREEN} from '../constants';


class Routes extends Component {
    // returns the layout
    getLayout = () => {
        if (this.props.layout.layoutType === FULL_SCREEN) {
            // Full screen is allowed when logged out using shared dashboards
            return FullScreenLayout;
        } if (!isUserAuthenticated()) {
            return AuthLayout;
        } else {
            return HorizontalLayout;
        }
    };

    shouldComponentUpdate(nextProps) {
        let oldLayout = { ...this.props.layout };
        delete oldLayout.showRightSidebar;
        let newLayout = { ...nextProps.layout };
        delete newLayout.showRightSidebar;
        return (
            JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
            JSON.stringify(this.props.userAuthenticated) !== JSON.stringify(nextProps.userAuthenticated)
        );
    }

    render() {
        const Layout = this.getLayout();
        // rendering the router with layout
        return (
            <BrowserRouter>
                <Layout {...this.props}>
                    <Switch>
                        {routes.map((route, index) => {
                            return (
                                !route.children && (
                                    <route.route
                                        key={index}
                                        path={route.path}
                                        roles={route.roles}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                )
                            );
                        })}
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        userAuthenticated: state.Auth.userAuthenticated,
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
