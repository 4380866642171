// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function configureStore(initialState: {}) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    const store = createStore(
        reducers, 
        initialState, 
        composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
    );
    sagaMiddleware.run(sagas);
    return store;
}
