/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {useEffect, useMemo, useState} from 'react'
import {Badge, Button, Col, CustomInput, FormGroup, Input, Label, Row, UncontrolledTooltip} from 'reactstrap'
import {
    DEFAULT_DECIMAL_PLACES,
    DurationSamplePoint,
    DurationStatus,
    EventTagType,
    EventType,
    GraphReportAnnotation,
    GraphReportRequestOptions,
    ReportType,
    LineChartStrokeType,
    StatisticType,
    Variable,
    VariableDataType
} from '../../../constants/api';
import {Options} from '../../GraphCreatorModal';
import {GraphColourOptions} from '../../../constants/graphs';
import {DataSource} from './GraphReportOptions';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import classNames from 'classnames';
import Select from '../../Select';
import {acceptsOptions, getVariableValue} from '../../../helpers/common';
import {InputForVariableType} from '../../../pages/configuration/general/GeneralConfiguration';
import {SingleValue} from 'react-select';

const DURATION_STATUS_HELP_TEXT =
    '"All" will report on all Durations. "Open Only" will report only on Durations that are ongoing.' +
    ' "Closed Only" will report only on Durations that have ended.'

const getDecimalPlacesHelpText = (defaultDecimalPlaces: number) =>
    'Round values on this report to a custom number of decimal places.' +
    ` This overrides the default of ${defaultDecimalPlaces}dp.`

// Match a 1dp float/integer suffixed with a d, h, m, or s.
const DURATION_TARGET_PATTERN = /^\s*(?=.*[0-9])\d*(?:\.\d)?[dhms]\s*$/;

export const DurationUnit = {
    Days: 'd',
    Hours: 'h',
    Minutes: 'm',
    Seconds: 's',
}

const FIVE_MINUTES = {value: 5, unit: DurationUnit.Minutes}

// TODO: Remove once MIT-324 is implemented
// A set of report types that act on duration events, but count them like instances and take target min/max parameters.
const DURATION_COUNT_CHARTS = new Set([
    'PERCENTAGE_DURATION_COUNT',
    'GAUGE_DURATION_COUNT',
])

interface AdditionalOptionsProps {
    graphReportType: ReportType,
    values: GraphReportRequestOptions,
    onChange: (options: GraphReportRequestOptions, goToNextPage: boolean) => void,
    dataSourceType: DataSource,
    groupableTags: EventTagType[],
    chosenEventTypes: EventType[],
    onEventTypesReordered: (types: EventType[]) => void,
    reportVariablesById: Record<number, Variable>,
    internalVariablesById: Record<number, Variable>,
}

interface AdditionalOptionsPickerBaseProps {
    selectedValue?: GraphReportRequestOptions,
    onSelect: (key: keyof GraphReportRequestOptions, value: any) => void,
    [key: string]: any,
}

function AdditionalOptions({
    graphReportType,
    values,
    dataSourceType,
    onChange,
    groupableTags,
    chosenEventTypes,
    onEventTypesReordered,
    reportVariablesById,
    internalVariablesById,
}: AdditionalOptionsProps) {

    const [optionValues, setOptionValues] = useState<GraphReportRequestOptions>(values)

    /**
     * A utility component for conditional rendering of components based on Graph Report options.
     * @param opts List of options to check for.
     * @param all If `true`, all the options in `opts` have to be present.
     * @param and Additional boolean condition to match for display.
     * @param fallback Optional component to display if condition was not met.
     */
    const AcceptsOptions = useMemo(() => ({
        opts,
        all = false,
        and = true,
        fallback,
        children
    }: {
        opts: (keyof Options)[],
        all?: boolean,
        and?: boolean,
        fallback?: React.ReactNode,
        children?: React.ReactNode
    }) => {
        const optionNames = graphReportType.options as (keyof GraphReportRequestOptions)[]
        const accepts = acceptsOptions(optionNames, opts, all)

        return <React.Fragment>{(accepts && and) ? children : (fallback ?? null)}</React.Fragment>
    }, [graphReportType])

    const setOption = (name: keyof GraphReportRequestOptions, value: any) => {
        setOptionValues(prevState => ({...prevState, [name]: value}))
    }

    const addAnnotation = (type: 'annotation_x' | 'annotation_y') => {
        const defaultValue = {
            annotation_x: '00:00:00',
            annotation_y: 0
        }[type]

        setOptionValues(prevState => {
            if (Array.isArray(prevState[type])) {
                return {
                    ...prevState,
                    [type]: [...prevState[type] as GraphReportAnnotation[], {value: defaultValue, label: 'Label'}],
                }
            } else {
                return {
                    ...prevState,
                    [type]: [{value: defaultValue, label: 'Label'}],
                }
            }
        })
    }

    const deleteAnnotation = (type: 'annotation_x' | 'annotation_y', index: number) => {
        if (optionValues[type] !== null) {
            setOptionValues(prevState => {
                const updated = [...prevState[type] as GraphReportAnnotation[]]
                updated.splice(index, 1)
                return {
                    ...prevState,
                    [type]: updated,
                }
            })
        }
    }

    const updateAnnotation = (type: 'annotation_x' | 'annotation_y', item: GraphReportAnnotation, index: number) => {
        setOptionValues(prevState => {
            const updated = [...prevState[type] as GraphReportAnnotation[]]
            updated.splice(index, 1, item)
            return {
                ...prevState,
                [type]: updated,
            }
        })
    }

    const hasOption = acceptsOptions.bind(
        undefined,
        graphReportType.options as (keyof GraphReportRequestOptions)[]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange(optionValues, false), [optionValues])

    return (
        <div className='trim-hr'>
            <AcceptsOptions all opts={['group_by_period', 'cumulative']}>
                <CumulativeToggle
                    selectedValue={optionValues}
                    onSelect={setOption}
                />
                <GroupByPeriodSelector
                    selectedValue={optionValues}
                    onSelect={setOption}
                />
                <AcceptsOptions opts={['duration_sample_point']}>
                    <DurationSamplePointSelect
                        selectedValue={optionValues}
                        onSelect={setOption}
                    />
                </AcceptsOptions>
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['filter_by_duration_status']} and={dataSourceType === 'durations'}>
                <FilterByDurationStatusSelect
                    selectedValue={optionValues}
                    onSelect={setOption}
                />
            </AcceptsOptions>
            <AcceptsOptions opts={['statistic_type']}>
                <StatisticTypeSelect
                    selectedValue={optionValues}
                    onSelect={setOption}
                />
            </AcceptsOptions>
            <AcceptsOptions opts={['decimal_places']}>
                <DecimalPlacesSelector
                    selectedValue={optionValues}
                    onSelect={setOption}
                    internalVariablesById={internalVariablesById}
                />
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions all opts={['target_minimum', 'target_maximum', 'target_colour_ascending']}>
                <TargetMinMaxAscendingSelector
                    className='mb-1'
                    eventType={'durations'}
                    selectedValue={optionValues}
                    onSelect={setOption}
                    isDuration={dataSourceType === 'durations' && !DURATION_COUNT_CHARTS.has(graphReportType.value)}
                    variables={reportVariablesById}
                />
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['stroke_curve']}>
                <LineChartStrokeStyle
                    className='mb-1'
                    selectedValue={optionValues}
                    onSelect={setOption}
                />
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['group_by_station', 'group_by_tag', 'group_by_user']}
                fallback={
                    <AcceptsOptions opts={['stacked_groups']}>
                        <StackedGroupsToggle
                            className='mb-1'
                            selectedValue={optionValues}
                            onSelect={setOption}
                        />
                    </AcceptsOptions>
                }>
                <GroupBy
                    className='mb-1'
                    selectedValue={optionValues}
                    onSelect={setOption}
                    groupableTags={groupableTags}
                    supportGroupByStation={hasOption(['group_by_station'])}
                    supportGroupByTag={hasOption(['group_by_tag'])}
                    supportGroupByUser={hasOption(['group_by_user'])}
                />
                <AcceptsOptions
                    opts={['stacked_groups']}
                    and={optionValues.group_by_station || optionValues.group_by_tag !== undefined}>
                    <StackedGroupsToggle
                        className='mt-2 mb-1'
                        selectedValue={optionValues}
                        onSelect={setOption}
                    />
                </AcceptsOptions>
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['calculation_type']}>
                <CalculationBuilder
                    className='mb-1'
                    chosenEventTypes={chosenEventTypes}
                    selectedValue={optionValues}
                    onSelect={setOption}
                    onEventTypesReordered={onEventTypesReordered}
                />
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['annotation_x']}>
                <Row className='justify-content-between mx-1'>
                    <h4>X Axis Annotations</h4>
                    <Button
                        color='primary text-white mb-2'
                        onClick={() => addAnnotation('annotation_x')}>
                        Add Annotation
                        <i className='mdi mdi-plus ml-1'/>
                    </Button>
                </Row>
                {optionValues.annotation_x?.map((annotation, idx) =>
                    <AnnotationBuilder
                        key={idx}
                        annotation={annotation}
                        onSelect={(key, item) => updateAnnotation(key, item, idx)}
                        onDelete={() => deleteAnnotation('annotation_x', idx)}
                        axis='X'
                        variables={reportVariablesById}
                    />
                )}
            </AcceptsOptions>
            <hr/>
            <AcceptsOptions opts={['annotation_y']}>
                <Row className='justify-content-between mx-1'>
                    <h4>Y Axis Annotations</h4>
                    <Button
                        color='primary text-white mb-2'
                        onClick={() => addAnnotation('annotation_y')}>
                        Add Annotation
                        <i className='mdi mdi-plus ml-1'/>
                    </Button>
                </Row>
                {optionValues.annotation_y?.map((annotation, idx) =>
                    <AnnotationBuilder
                        key={idx}
                        annotation={annotation}
                        onSelect={(key, item) => updateAnnotation(key, item, idx)}
                        onDelete={() => deleteAnnotation('annotation_y', idx)}
                        axis='Y'
                        variables={reportVariablesById}
                        durationYAxis={dataSourceType === 'durations'
                            && hasOption(['statistic_type'])
                            && optionValues['statistic_type'] !== 'Count'
                        }
                    />
                )}
            </AcceptsOptions>
            <hr/>
            <Button
                block
                color='primary text-white mt-3'
                onClick={() => onChange(optionValues, true)}>
                Preview Chart
            </Button>
        </div>
    )
}

interface GroupByProps extends AdditionalOptionsPickerBaseProps {
    groupableTags: EventTagType[],
    supportGroupByUser?: boolean,
    supportGroupByTag?: boolean,
    supportGroupByStation?: boolean,
}

type GroupByOptions = 'Station' | 'Tag' | 'User' | 'None';

function GroupBy({
    onSelect,
    selectedValue,
    groupableTags,
    supportGroupByUser = true,
    supportGroupByTag = true,
    supportGroupByStation = true,
    ...rest
}: GroupByProps) {

    const options = useMemo(() => [
        supportGroupByStation && {label: 'Station', value: 'Station'},
        supportGroupByTag && {label: 'Tag', value: 'Tag'},
        supportGroupByUser && {label: 'User', value: 'User'},
        {label: 'None', value: 'None'},
    ].filter(it => it !== false),
        [supportGroupByStation, supportGroupByTag, supportGroupByUser]
    )

    const tagOptions = useMemo(() => {
        if (supportGroupByTag) {
            return groupableTags.map(it => ({value: it.id, label: it.name}))
        } else return []
    }, [groupableTags, supportGroupByTag])

    let value: GroupByOptions
    let selectedTag: EventTagType | undefined

    if (selectedValue?.group_by_station === true) {
        value = 'Station'
    } else if (selectedValue?.group_by_user === true) {
        value = 'User'
    } else if (selectedValue?.group_by_tag !== undefined) {
        selectedTag = groupableTags.find(it => it.id === selectedValue?.group_by_tag)
        if (selectedTag) {
            value = 'Tag'
        } else {
            value = 'None'
        }
    } else {
        value = 'None'
    }

    const [showTagSelect, setShowTagSelect] = useState(value === 'Tag')

    const onSelectionChanged = (selection: SingleValue<{value: GroupByOptions, label: GroupByOptions}>) => {
        onSelect('group_by_station', false)
        onSelect('group_by_user', false)
        onSelect('group_by_tag', undefined)

        setShowTagSelect(selection?.value === 'Tag')

        if (selection !== null) {
            switch (selection.value) {
                case 'Station':
                    onSelect('group_by_station', true)
                    break
                case 'Tag':
                    onSelect('group_by_tag', groupableTags[0]?.id)
                    break
                case 'User':
                    onSelect('group_by_user', true)
                    break
            }
        }
    }

    return (
        <div {...rest}>
            <Row>
                <Col sm={12} md={showTagSelect ? 6 : 12}>
                    <Label>Group By:</Label>
                    <Select
                        isClearable={value === 'Station' || value === 'Tag' || value === 'User'}
                        value={{value: value, label: value}}
                        // @ts-ignore
                        options={options as {label: string, value: string}}
                        isOptionDisabled={option => option.value === 'Tag' && groupableTags.length === 0}
                        onChange={onSelectionChanged}
                    />
                </Col>
                {showTagSelect &&
                    <Col>
                        <Label>Tag:</Label>
                        <Select
                            options={tagOptions}
                            value={{label: selectedTag?.name, value: selectedTag?.id}}
                            onChange={selection => {
                                onSelect('group_by_tag', selection?.value ?? null)
                            }}
                        />
                    </Col>
                }
            </Row>
        </div>
    )
}

export type TimeDuration = {value: number, unit: string}

/**
 * Convert TimeDuration object to a string such as 5m or 25d.
 * @param timeDuration The TimeDuration object to convert to a string.
 */
export const timeDurationToString = (timeDuration: TimeDuration): string => {
    return `${timeDuration.value}${timeDuration.unit}`
}

/**
 * Convert a string such as 5m or 25d into a TimeDuration object.
 * @param durationString The string to convert to a TimeDuration.
 */
export function stringToTimeDuration(durationString?: string): TimeDuration | undefined {
    if (durationString !== undefined && durationString.match(DURATION_TARGET_PATTERN)) {
        const suffix = durationString.substring(durationString.length - 1)
        const number = Number(durationString.substring(0, durationString.length - 1))
        if (!isNaN(number)) {
            return {value: number, unit: suffix}
        }
    }
}

interface TimePeriodSelectorProps {
    onSelect: (result: TimeDuration) => void,
    onLimitToStartOfDayChanged?: (result: boolean) => void,
    selectedValue?: TimeDuration,
    limitToStartOfDayValue?: boolean
    updateOnChange?: boolean,
    disabled?: boolean,
    showLimitByStartOfDayOption?: boolean
    startOfDayDisplay?: string,
    nextButton?: (disabled: boolean, onClick: () => void) => React.ReactNode
    [key: string]: any,
}

export const DurationUnitLabels: {[key: string]: string} = {
    d: 'Days',
    h: 'Hours',
    m: 'Minutes',
}

function TimePeriodSelector({
    onSelect,
    onLimitToStartOfDayChanged,
    selectedValue,
    limitToStartOfDayValue,
    updateOnChange = false,
    disabled = false,
    showLimitByStartOfDayOption,
    startOfDayDisplay,
    nextButton,
    ...rest
}: TimePeriodSelectorProps) {

    const [value, setValue] = useState(selectedValue?.value ?? 1)
    const [unit, setUnit] = useState<string>(selectedValue?.unit || DurationUnit.Days)
    const [startOfDayValue, setStartOfDayValue] = useState<boolean>(limitToStartOfDayValue || false)

    const valid = !isNaN(Number(value)) && value.toString().length > 0

    useEffect(() => {
        if (updateOnChange === true && valid) {
            onSelect({value, unit})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, unit, updateOnChange, valid])

    const startOfDayChanged = (e: any) => {
        setStartOfDayValue(e.target.checked)
        onLimitToStartOfDayChanged!!(e.target.checked)
    }

    const onNextButtonClick = () => onSelect({value, unit})

    return (
        <div {...rest}>
            <Row>
                <Col sm={showLimitByStartOfDayOption ? 4 : 8} className='pr-1'>
                    <FormGroup className='mb-0'>
                        <Input
                            type='number'
                            inputMode='numeric'
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>
                <Col sm={4} className='pl-1'>
                    <FormGroup className='mb-0'>
                        <Select
                            options={Object.entries(DurationUnitLabels).map(([value, label]) => ({label, value}))}
                            defaultValue={{label: DurationUnitLabels[unit], value: unit}}
                            onChange={selection => setUnit(selection!!.value)}
                            isDisabled={disabled}
                        />
                    </FormGroup>
                </Col>
                {showLimitByStartOfDayOption && <Col sm={4}>
                    <Row className='mt-1'>
                        <CustomInput
                            type='checkbox'
                            id='limitStartOfDay'
                            onChange={startOfDayChanged}
                            checked={startOfDayValue}
                            label={`Limit to Start of Day (${startOfDayDisplay})`}
                        />
                    </Row>
                </Col>}
            </Row>
            {(nextButton !== undefined) &&
                <>
                    <hr/>
                    <Row>
                        <Col>
                            {nextButton?.(disabled || !valid, onNextButtonClick)}
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}

interface TargetMinMaxAscendingSelectorProps extends AdditionalOptionsPickerBaseProps {
    eventType: DataSource,
    isDuration: boolean,
    variables: Record<number, Variable>,
}

function TargetMinMaxAscendingSelector({
    onSelect,
    selectedValue,
    eventType,
    isDuration,
    variables,
    ...rest
}: TargetMinMaxAscendingSelectorProps) {

    const [isVariable, setIsVariable] = useState([
        selectedValue?.target_minimum?.startsWith('$') === true,
        selectedValue?.target_maximum?.startsWith('$') === true,
    ])

    useEffect(() => {
        setIsVariable([
            selectedValue?.target_minimum?.startsWith('$') === true,
            selectedValue?.target_maximum?.startsWith('$') === true,
        ])
    }, [selectedValue?.target_minimum, selectedValue?.target_maximum])

    useEffect(() => {
        // Clear gradient selection if group by station is selected.
        if (selectedValue?.group_by_station === true) {
            onSelect('target_colour_ascending', undefined)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue?.group_by_station])

    const rangeFields: Record<string, 'target_minimum' | 'target_maximum'> = {
        'Range Minimum:': 'target_minimum',
        'Range Maximum:': 'target_maximum',
    }

    return (
        <div {...rest}>
            {Object.entries(rangeFields).map(([title, field], index) =>
                <React.Fragment key={index}>
                    <Label>{title}</Label>
                    <Row className='mb-1'>
                        <Col xs={4}>
                            <VariableOrConstantSelect
                                value={isVariable[index] ? 'variable' : 'constant'}
                                onChange={result => setIsVariable(prevState => {
                                    const newState = [...prevState]
                                    newState[index] = result === 'variable'
                                    return newState
                                })}
                            />
                        </Col>
                        <Col xs={8}>
                            {isVariable[index] ?
                                <VariableSelect
                                    variables={variables}
                                    variableType={isDuration ? 'DURATION' : 'NUMERIC'}
                                    value={Number(selectedValue?.[field]?.replace('$', ''))}
                                    onChange={(selection) => onSelect(field, `$${selection.id}`)}
                                /> :
                                isDuration ?
                                    <TimePeriodSelector
                                        className='mb-1'
                                        selectedValue={stringToTimeDuration(selectedValue?.[field]) || {
                                            value: 0, unit: DurationUnit.Seconds
                                        }}
                                        onSelect={(period) => onSelect(
                                            field,
                                            `${period.value}${period.unit}`
                                        )}
                                        updateOnChange={true}
                                    /> :
                                    <Input
                                        type='number'
                                        inputMode='numeric'
                                        defaultValue={selectedValue?.[field]}
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            if (value && !isNaN(value)) {
                                                onSelect(field, value)
                                            }
                                        }}
                                    />
                            }
                        </Col>
                    </Row>
                </React.Fragment>
            )}
            {/* Colour gradient not allowed when grouping by station because
                then you can't distinguish which series corresponds to each station. */}
            {!selectedValue?.group_by_station &&
                <>
                    <Label>Colour:</Label>
                    <Select
                        isClearable
                        options={GraphColourOptions}
                        onChange={selection => {
                            if (selection === null) {
                                onSelect('target_colour_ascending', undefined)
                            } else {
                                onSelect('target_colour_ascending', selection.value)
                            }
                        }}
                        value={
                            GraphColourOptions.find(
                                option => option.value === selectedValue?.target_colour_ascending
                            ) || {value: null, label: 'None'}
                        }
                    />
                </>
            }
        </div>
    )
}

function LineChartStrokeStyle({selectedValue, onSelect, ...rest}: AdditionalOptionsPickerBaseProps) {

    const options = [
        {
            value: 'straight' as LineChartStrokeType,
            title: 'Straight',
            icon: 'mdi-chart-line-variant',
        }, {
            value: 'smooth' as LineChartStrokeType,
            title: 'Smooth',
            icon: 'mdi-chart-bell-curve-cumulative',
        }, {
            value: 'stepline' as LineChartStrokeType,
            title: 'Stepped',
            icon: 'mdi-stairs',
        }, {
            value: 'scatter' as LineChartStrokeType,
            title: 'None',
            icon: 'mdi-chart-scatter-plot',
        }
    ];

    return (
        <div {...rest}>
            <h4>Line Type:</h4>
            <BigButtonPicker
                className='mb-2'
                onItemSelected={(value) => onSelect('stroke_curve', value)}
                selectedItem={selectedValue?.stroke_curve || null}
                options={options}
            />
        </div>
    )
}

function StackedGroupsToggle({selectedValue, onSelect, ...rest}: AdditionalOptionsPickerBaseProps) {

    const options = [
        {
            value: true,
            title: 'Stacked',
            icon: 'mdi-chart-bar-stacked',
        }, {
            value: false,
            title: 'Grouped',
            icon: 'mdi-chart-bar',
        }
    ];

    return (
        <div {...rest}>
            <Label>Group Display:</Label>
            <BigButtonPicker
                className='mb-2'
                onItemSelected={(value) => onSelect('stacked_groups', value)}
                selectedItem={selectedValue?.stacked_groups}
                options={options}
            />
        </div>
    )
}

interface BigButtonPickerProps<T> {
    onItemSelected: (value: T) => void,
    selectedItem: T | null,
    options: {
        value: T,
        title: React.ReactNode,
        icon: string,
    }[],
    [key: string]: any
}

export function BigButtonPicker<T>({options, selectedItem, onItemSelected, ...rest}: BigButtonPickerProps<T>) {

    const buttonSize = {height: '125px', width: '150px', borderRadius: '1rem', borderWidth: '2px', marginRight: '1.5rem'}

    const buttonColorFor = (button: T): string => {
        return (selectedItem === button) ?
            'primary text-white' : 'outline-primary'
    }

    return (
        <div {...rest}>
            {options.map((option, index) =>
                <Button
                    key={index}
                    color={buttonColorFor(option.value)}
                    style={buttonSize}
                    onClick={() => onItemSelected(option.value)}>
                    <div>
                        <i className={`display-4 mdi ${option.icon}`}/>
                    </div>
                    <strong>
                        {option.title}
                    </strong>
                </Button>
            )}
        </div>
    )
}

interface CalulationBuilderProps extends AdditionalOptionsPickerBaseProps {
    chosenEventTypes: EventType[],
    onEventTypesReordered: (types: EventType[]) => void,
}

export function CalculationBuilder({
    chosenEventTypes,
    selectedValue,
    onSelect,
    onEventTypesReordered,
}: CalulationBuilderProps) {

    const operatorOptions = {
        ADDITION: {value: 'ADDITION', label: 'Plus (+)', operator: '+'},
        SUBTRACTION: {value: 'SUBTRACTION', label: 'Minus (-)', operator: '-'},
    }

    const [selectedOperator, setSelectedOperator] = useState(selectedValue?.calculation_type ?
        operatorOptions[selectedValue.calculation_type] :
        operatorOptions.ADDITION
    )
    useEffect(() => {
        onSelect('calculation_type', selectedOperator.value)
        onEventTypesReordered(chosenEventTypes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOperator, chosenEventTypes])

    const onDragEnd = (result: DropResult) => {
        if (result.destination) {
            const copy = [...chosenEventTypes]
            const removed = copy.splice(result.source.index, 1)
            copy.splice(result.destination.index, 0, ...removed)
            onEventTypesReordered(copy)
            onSelect('calculation_type', selectedOperator.value)
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Label>Calculation:</Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col sm={4}>
                    <div className='mb-1'>Operation:</div>
                    <Select
                        options={Object.values(operatorOptions)}
                        value={selectedOperator}
                        onChange={selection => {
                            setSelectedOperator(selection!!)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='mb-1'>Drag to re-order items in expression.</div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={'calculation'}
                            direction='horizontal'>
                            {(droppableProvided, droppableSnapshot) => (
                                <div ref={droppableProvided.innerRef}>
                                    {chosenEventTypes.map((event, index, array) =>
                                        [
                                            <Draggable
                                                key={event.id}
                                                draggableId={`${event.name}.${event.id}`}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <Badge
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        innerRef={provided.innerRef}
                                                        className='hover-enlarge font-12 p-1'
                                                        color='secondary text-white'>
                                                        <i className='mdi mdi-drag-vertical mr-1 ml-n1'/>
                                                        {event.name}
                                                    </Badge>
                                                )}
                                            </Draggable>,
                                            <Label
                                                key={`${event.id}operator`}
                                                className={classNames('mx-1', {'d-none': index === array.length - 1 && !droppableSnapshot.isUsingPlaceholder})}>
                                                {selectedOperator.operator}
                                            </Label>
                                        ]
                                    )}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Col>
            </Row>
        </div>
    )
}

function FilterByDurationStatusSelect({selectedValue, onSelect}: AdditionalOptionsPickerBaseProps) {

    const [selection, setSelection] = useState<DurationStatus | undefined>(selectedValue?.filter_by_duration_status)
    useEffect(() => {
        onSelect('filter_by_duration_status', selection)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    return (
        <Row className={'mb-2'}>
            <Col>
                <Label>Duration Status:</Label>
                <i id='duration-status-helper' className='mdi mdi-help-circle ml-1'/>
                <UncontrolledTooltip trigger='hover' target='duration-status-helper' placement='right'>
                    {DURATION_STATUS_HELP_TEXT}
                </UncontrolledTooltip>
                <div className={'d-flex'}>
                    <FormGroup check className='mr-2' onClick={() => setSelection(undefined)}>
                        <Input
                            type='radio'
                            name='active'
                            checked={selection === undefined}
                        />
                        <Label check htmlFor='duration-status-all'>All</Label>
                    </FormGroup>
                    <FormGroup check className='mr-2' onClick={() => setSelection(DurationStatus.OPEN)}>
                        <Input
                            type='radio'
                            name='duration-status-open-only'
                            checked={selection === DurationStatus.OPEN}
                        />
                        <Label check htmlFor='duration-status-open-only'>Open Only</Label>
                    </FormGroup>
                    <FormGroup check onClick={() => setSelection(DurationStatus.CLOSED)}>
                        <Input
                            type='radio'
                            name='duration-status-closed-only'
                            checked={selection === DurationStatus.CLOSED}
                        />
                        <Label check htmlFor='duration-status-closed-only'>Closed Only</Label>
                    </FormGroup>
                </div>
            </Col>
        </Row>
    )
}

function StatisticTypeSelect({selectedValue, onSelect}: AdditionalOptionsPickerBaseProps) {

    const [selection, setSelection] = useState<string>(selectedValue?.statistic_type || 'Sum')
    useEffect(() => {
        onSelect('statistic_type', selection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    return (
        <Row className={'mb-2'}>
            <Col>
                <Label>Statistic Type:</Label>
                <Select
                    options={Object.values(StatisticType).map(it => ({label: it, value: it}))}
                    value={{value: selection, label: selection}}
                    onChange={selection => setSelection(selection?.value ?? 'Sum')}
                />
            </Col>
        </Row>
    )
}

interface DecimalPlacesSelectorProps extends AdditionalOptionsPickerBaseProps {
    internalVariablesById: Record<number, Variable>
}

function DecimalPlacesSelector({selectedValue, onSelect, internalVariablesById}: DecimalPlacesSelectorProps) {

    const defaultDecimalPlaces = useMemo(
        () => Object.values(internalVariablesById).find(it => it.name === DEFAULT_DECIMAL_PLACES)!!.numeric_value!!,
        [internalVariablesById]
    )

    const [enabled, setEnabled] = useState<boolean>(selectedValue?.decimal_places !== undefined)
    const [selection, setSelection] = useState<number>(selectedValue?.decimal_places ?? defaultDecimalPlaces)

    useEffect(() => {
        onSelect('decimal_places', enabled ? selection : undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection, enabled])

    return (
        <>
            <Row className={'mx-0'}>
                <CustomInput
                    id={'decimalPlacesEnabled'}
                    type={'checkbox'}
                    checked={enabled}
                    onChange={e => setEnabled(e.target.checked)}
                    label='Custom Rounding'
                />
                <i id='decimal-places-helper' className='mdi mdi-help-circle ml-1'/>
                <UncontrolledTooltip trigger='hover' target='decimal-places-helper' placement='right'>
                    {getDecimalPlacesHelpText(defaultDecimalPlaces)}
                </UncontrolledTooltip>
            </Row>
            {enabled &&
                <Input
                    className='mb-1'
                    type='number'
                    min={0}
                    max={9}
                    value={selection}
                    onChange={(e) => setSelection(e.target.valueAsNumber)}
                />
            }
        </>
    )
}

function CumulativeToggle({selectedValue, onSelect}: AdditionalOptionsPickerBaseProps) {
    const [selection, setSelection] = useState<boolean>(selectedValue?.cumulative ?? false)
    useEffect(() => {
        onSelect('cumulative', selection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    return (
        <Row className={'mx-0'}>
            <CustomInput
                id={'cumulative'}
                type={'checkbox'}
                checked={selection}
                onChange={e => setSelection(e.target.checked)}
                label='Cumulative Trend'
            />
        </Row>
    )
}

function GroupByPeriodSelector({selectedValue, onSelect}: AdditionalOptionsPickerBaseProps) {
    const [enabled, setEnabled] = useState<boolean>(selectedValue?.group_by_period !== undefined)
    const [selection, setSelection] = useState<string>(selectedValue?.group_by_period ?? '5m')
    useEffect(() => {
        onSelect('group_by_period', enabled ? selection : '0m')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection, enabled])

    return (
        <>
            <Row className={'mx-0'}>
                <CustomInput
                    id={'groupByPeriodEnabled'}
                    type={'checkbox'}
                    checked={enabled}
                    onChange={e => setEnabled(e.target.checked)}
                    label='Group By Period'
                />
            </Row>
            <TimePeriodSelector
                className='mb-1'
                selectedValue={stringToTimeDuration(selection) || FIVE_MINUTES}
                onSelect={(period) => setSelection(`${period.value}${period.unit}`)}
                updateOnChange={true}
                disabled={!enabled}
            />
        </>
    )
}

function DurationSamplePointSelect({selectedValue, onSelect}: AdditionalOptionsPickerBaseProps) {
    const [selection, setSelection] =
        useState<string>(selectedValue?.duration_sample_point || DurationSamplePoint.START)
    useEffect(() => {
        onSelect('duration_sample_point', selection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Label>Duration Sample Point:</Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Select
                        options={Object.values(DurationSamplePoint).map(it => ({label: it, value: it}))}
                        value={{value: selection, label: selection}}
                        onChange={selection => setSelection(selection?.value ?? DurationSamplePoint.START)}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

type VariableOrConstant = 'variable' | 'constant'

interface VariableOrConstantSelectProps {
    value: VariableOrConstant,
    onChange: (newValue: VariableOrConstant) => void,
}

function VariableOrConstantSelect({value, onChange}: VariableOrConstantSelectProps) {
    const options: Record<VariableOrConstant, { label: string, value: VariableOrConstant }> = {
        constant: {label: 'Constant', value: 'constant'},
        variable: {label: 'Variable', value: 'variable'},
    }
    return (
        <Select
            options={Object.values(options)}
            value={options[value]}
            onChange={it => it && onChange(it.value)}
        />
    )
}

interface VariableSelectProps {
    variables: Record<number, Variable>,
    variableType: VariableDataType,
    value?: number,
    onChange: (newValue: Variable) => void,
}

function VariableSelect({variables, variableType, value, onChange}: VariableSelectProps) {
    return (
        <Select
            options={Object.values(variables)
                .filter(it => it.type === variableType)
                .map(it => ({label: `${it.name} (${getVariableValue(it)})`, value: it.id}))
            }
            value={(value !== undefined && variables[value] !== undefined) ?
                {
                    label: `${variables[value].name} (${getVariableValue(variables[value])})`,
                    value: variables[value].id
                } : null
            }
            onChange={it => it && onChange(variables[it.value])}
        />
    )
}

interface AnnotationBuilderProps extends Omit<AdditionalOptionsPickerBaseProps, 'onSelect'> {
    annotation: GraphReportAnnotation,
    variables: Record<number, Variable>,
    axis: 'X' | 'Y',
    durationYAxis?: boolean,
    onSelect: (key: 'annotation_x' | 'annotation_y', value: any) => void,
    onDelete: () => void,
}

function AnnotationBuilder({annotation, axis, variables, onSelect, onDelete, durationYAxis}: AnnotationBuilderProps) {
    const key = axis === 'X' ? 'annotation_x' : 'annotation_y'
    const isConstant = annotation.variable === undefined
    const variableId = annotation.variable
    const variableSelectType: VariableDataType = (axis === 'Y') ?
        durationYAxis ? 'DURATION' : 'NUMERIC' :
        'TIME'

    const [variableOrConstant, setVariableOrConstant] = useState<'constant' | 'variable'>(isConstant ? 'constant' : 'variable')

    const [label, setLabel] = useState(annotation.label ?? '')
    const [selectedVariable, setSelectedVariable] =
        useState<Variable | undefined>(variableId ? variables?.[variableId] : undefined)

    const [constant, setConstant] = useState<string>(isConstant ? annotation.value ?? '' : '')

    useEffect(() => {
        if (variableOrConstant === 'variable' && selectedVariable !== undefined) {
            onSelect(key, {label, value: `$${selectedVariable.id}`, variable: selectedVariable.id})
        } else if (constant.length > 0) {
            onSelect(key, {label, value: constant, variable: undefined})
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableOrConstant, selectedVariable, constant, label])

    return (
        <React.Fragment>
            <Row className='mt-2'>
                <Col>
                    <VariableOrConstantSelect
                        value={variableOrConstant}
                        onChange={setVariableOrConstant}
                    />
                </Col>
                <Col>
                    <Input
                        value={label}
                        placeholder='Annotation Label'
                        onChange={e => setLabel(e.target.value)}
                    />
                </Col>
                <Col>
                    {variableOrConstant === 'variable' ?
                        <VariableSelect
                            variables={variables}
                            variableType={variableSelectType}
                            value={selectedVariable?.id}
                            onChange={setSelectedVariable}
                        /> :
                        <InputForVariableType
                            type={variableSelectType}
                            value={constant}
                            onChange={setConstant}
                        />
                    }
                </Col>
                <button className='btn' onClick={onDelete} title='Remove Annotation'>
                    <i className='mdi mdi-close'/>
                </button>
            </Row>
        </React.Fragment>
    )
}

export {AdditionalOptions, TimePeriodSelector}
