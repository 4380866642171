/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {UserType} from '../redux/user/types'
import {postOrGoLogin} from '../helpers/api'
import {TableData} from '../components/DashboardWidgets/TabularReport'
import {ReportTextSize} from './common'

export function prependPath(object: any, path?: string) {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && 'path' in object[key]) {
            let prependedPath = (path ?? '') + object[key].path + '/';
            object[key].path = prependedPath;
            // TODO: Create a function (as object[key].path) that accepts all path variables (RegEx: /\/:[^\/]+/)
            // as arguments, and returns the prependedPath with the path variables substituted with the parameters.

            prependPath(object[key], prependedPath);
        }
    });
}

const API_SCHEMA = (version: string) => ({
    path: '/api/',
    LOGIN: {path: 'login'},
    LOGOUT: {path: 'logout'},
    FORGOT_PASSWORD: {
        path: 'forgot-password',
        CHANGE_PASSWORD: {path: 'confirm'},
    },
    VERSION: {
        path: version,
        DASHBOARDS: {
            path: 'dashboards',
            DASHBOARD: {
                path: ':dashboardId',
                response: {} as DashboardResponse,
            },
            SHARE: {
                path: 'share',
                response: {} as SharedDashboardResponse,
            },
            SHARED: {
                path: 'shared',
                response: {} as SharedDashboardResponse,
                TOKEN: {
                    path: ':token',
                    response: {} as SharedDashboardResponse,
                }
            }
        },
        EVENTS: {
            path: 'events',
            INSTANCES: {
                path: 'instances',
                response: {} as PaginatedResults<InstanceEvent>,
                INSTANCE: {
                    path: ':instanceId',
                    response: {} as InstanceEvent,
                },
                EXPORT: {
                    path: 'export',
                    response: {} as any
                }
            },
            DURATIONS: {
                path: 'durations',
                response: {} as PaginatedResults<DurationEvent>,
                DURATION: {
                    path: ':durationId',
                    response: {} as DurationEvent,
                },
                EXPORT: {
                    path: 'export',
                    response: {} as any
                }
            },
            TYPES: {
                path: 'types',
                response: {} as { duration_types: DurationType[], instance_types: InstanceType[] },
                CATEGORIES: {
                    path: 'categories',
                    response: {} as { duration_categories: EventCategory[], instance_categories: EventCategory[] },
                    INSTANCES: {
                        path: 'instances',
                        INSTANCE: {
                            path: ':eventTypeId',
                            response: {} as EventCategory,
                        },
                    },
                    DURATIONS: {
                        path: 'durations',
                        DURATION: {
                            path: ':eventCategoryId',
                            response: {} as EventCategory,
                        },
                    },
                },
                INSTANCES: {
                    path: 'instances',
                    INSTANCE: {
                        path: ':eventTypeId',
                        response: {} as InstanceType,
                        ARCHIVE: {path: 'archive'},
                        RESTORE: {path: 'restore'},
                    },
                },
                DURATIONS: {
                    path: 'durations',
                    DURATION: {
                        path: ':eventTypeId',
                        response: {} as DurationType,
                        ARCHIVE: {path: 'archive'},
                        RESTORE: {path: 'restore'},
                    },
                },
            },
            TAGS: {
                path: 'tags',
                TYPES: {
                    path: 'types',
                    response: [] as EventTagType[],
                    TYPE: {path: ':eventTagTypeId'}
                },
            },
        },
        REPORTS: {
            path: 'reports',
            TABULAR: {
                path: 'tabular',
                RUN: {path: 'run'},
            },
            GRAPHS: {
                path: 'graphs',
                response: [] as GraphReportResponse[],
                GRAPH: {
                    path: ':graphReportId',
                    response: {} as GraphReportResponse,
                    RUN: {
                        path: 'run',
                        response: {} as GraphReportResults
                    },
                    COPY: {
                        path: 'copy',
                        response: {} as GraphReportResponse
                    }
                },
                TYPES: {
                    path: 'types',
                    response: {} as ReportType[]
                },
                RUN: {
                    path: 'run',
                    response: {} as GraphReportResults
                }
            },
            CUSTOM: {
                path: 'custom',
                TYPES: {
                    path: 'types',
                    response: [] as ReportType[],
                },
                RUN: {
                    path: ':name/run',
                    response: {} as any,
                    run: function (name: string, options: any = {}): Promise<GraphReportResults> {
                        return postOrGoLogin(this.path.replace(':name', name), options)
                    }
                }
            },
            VARIABLES: {
                path: 'variables',
                response: [] as Variable[],
                VARIABLE: {
                    path: ':variableId',
                    response: {} as Variable,
                },
                NUMERIC: {
                    path: 'numeric',
                    response: [] as Variable[],
                },
                TEXT: {
                    path: 'text',
                    response: [] as Variable[],
                },
                DATETIME: {
                    path: 'datetime',
                    response: [] as Variable[],
                },
                TIME: {
                    path: 'time',
                    response: [] as Variable[],
                },
            }
        },
        STATIONS: {
            path: 'stations',
            response: [] as Station[],
            STATION: {
                path: ':stationId',
                response: {} as Station,
                RESET_AUTHENTICATION_TOKEN: {
                    path: 'reset-authentication-token',
                    response: {} as Station,
                }
            },
            TYPES: {
                path: 'types',
                response: {} as StationType[],
                TYPE: {
                    path: ':stationTypeId',
                    response: {} as StationType,
                },
            },
            GROUPS: {
                path: 'groups',
                response: [] as StationGroup[],
                GROUP: {
                    path: ':stationGroupId',
                    response: {} as StationGroup,
                }
            },
            SCHEDULES: {
                path: 'schedules',
                SCHEDULE: {
                    path: ':stationScheduleId',
                    response: {} as StationSchedule
                }
            },
            USERS: {
                path: 'users',
                response: [] as StationUser[],
                USER: {
                    path: ':stationUserId',
                    response: {} as StationUser
                }
            }
        },
    },

    USERS: {
        path: 'users',
        response: {} as UserType[],
        USER: {
            path: ':userId',
            response: {} as UserType,
        },
        USER_SETUP: { path: 'user-setup' }
    },

    USER: {
        path: 'user',
        PROFILE_INFO: {
            path: 'get-profile-info',
            response: {} as UserType,
        },
        CHANGE_PASSWORD: {
            path: 'change-password',
        }
    },
    SUPPORT: {
        path: 'support',
    },
    CONFIG: {
        path: 'config',
        response: {} as Config,
    },
    NOTIFICATIONS: {
        path: 'notifications',
    },
    init: function() {
        prependPath(this, this.path);
        return this;
    }
})

export const DEFAULT_VERSION = 'v1'
export const API = API_SCHEMA(DEFAULT_VERSION).init();

export type WidgetType = 'ERROR' | 'GRAPH' | 'TEXT' | 'TIME'
export type EventTypeName = 'instances' | 'durations'

export interface WidgetData {
    id: number,
    widget_type: WidgetType,
    graph_report: number | null,
    text: string | null,
    background: boolean,
    x_position: number,
    y_position: number,
    width: number,
    height: number,
    options?: {
        dateFormat?: string
    }
}

export interface PaginatedResults<T> {
    next?: string
    previous?: string
    results: T[]
}

export interface NamedModel {
    id: number;
    name: string;
}

export interface HasColour {
    colour: string;
}

export interface EventCategory extends NamedModel {
    description?: string,
    types: EventType[],
}

export interface EventType extends NamedModel, HasColour {
    description?: string;
    display_name: string;
    category?: EventCategory;
    station_types: StationType[];
    active_from: string;
    active_till?: string;
}

export interface InstanceType extends EventType {}

export interface DurationType extends InstanceType {
    affects_throughput: boolean;
}

export interface StationSchedule extends NamedModel {
    station_group: number;
    start_days: string[];
    start_time: string;
    duration: string;
    active_from: string;
    active_till: string;
}

export interface StationGroup extends NamedModel {
    description: string;
    automanaged: boolean;
    stations: Station[];
    schedules: StationSchedule[];
    station_type: StationType,
}

export type OEECountType = 'OEE_GOOD_COUNT' | 'OEE_BAD_COUNT'

export interface DurationTypeStationTypes extends NamedModel {
    affects_throughput: boolean
}

export interface EventTagTypeStationTypes extends NamedModel {
    oee_count_type: OEECountType,
    data_type: EventTagDataType
}

export interface StationType extends NamedModel {
    description: string;
    stations: Station[];
    duration_types: DurationTypeStationTypes[];
    tag_types: EventTagTypeStationTypes[];
    heartbeat_period: number;
    ideal_cycle_time: string,
    bad_cycle_additional_loss: string,
    has_availability: boolean,
    has_performance: boolean,
    has_quality: boolean,
}

export interface Station extends NamedModel, HasColour {
    description: string;
    active_from: string;
    active_till?: any;
    auth_token: string;
    last_uptime: string;
    station_groups: number[];
    station_type: StationType;
}

export type EventTagDataType = 'TEXT' | 'NUM' | 'DATE';

export interface EventTagType extends NamedModel {
    display_name: string;
    data_type: EventTagDataType;
    station_types: number[],
}

export interface StationUser {
    id: number,
    username: string,
    full_name: string,
    active: boolean
}

export interface EventTag {
    id: number;
    tag_type: EventTagType;
    instance?: number;
    duration?: number;
    value: string;
}

export interface Event {
    id: number;
    station: number;
    display_data?: string;
    event_type: number;
    tags: {id: number, value: string, tag_type: number}[];
}

export interface DurationEvent extends Event {
    start_time: string;
    end_time?: string;
    duration?: number;
    user_start?: string;
    user_end?: string;
}

export interface InstanceEvent extends Event {
    id: number;
    station: number;
    timestamp: string;
    user?: string;
}

export interface Config {
    gRecaptcha: {
        siteKey: string,
    },
    sentry: {
        environment: string,
        enabled: boolean
    },
    subscription: {
        station_max_active_total: number
    }
}

export type GraphCalculationType = 'ADDITION' | 'SUBTRACTION'
export type ReportDataSource = 'Instance' | 'Duration' | 'OEE' | 'Tag Series'
export type LineChartStrokeType = 'straight' | 'smooth' | 'stepline' | 'scatter'
export type ReportPeriod = {start_date: string, end_date: string}
export enum StatisticType {
    SUM = 'Sum',
    AVERAGE = 'Average',
    MEDIAN = 'Median',
    COUNT = 'Count',
}
export enum TabularStatisticType {
    SUM = StatisticType.SUM,
    AVERAGE = StatisticType.AVERAGE,
    MEDIAN = StatisticType.MEDIAN,
}
export enum DurationSamplePoint {
    START = 'Start',
    MIDPOINT = 'Midpoint',
    END = 'End',
}
export enum DurationStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export interface ReportType {
    value: string,
    label: string,
    options: (keyof GraphReportResponseOptions)[],
    datasource : ReportDataSource[],
    category: 'Comparison' | 'Trend' | 'Distribution' | 'Percentage' | 'Gauge' | 'Tabular',
    deprecated: boolean,
    custom?: boolean,
}

export interface GraphReportAnnotation {
    label: string,
    value: string,
    variable?: number,
}

export interface GraphReportBaseOptions {
    filter_by_duration_status?: DurationStatus
    group_by_period?: string;
    stacked_groups?: boolean;
    target_minimum?: string;
    target_maximum?: string;
    target_colour_ascending?: boolean;
    calculation_type?: GraphCalculationType;
    show_last_duration?: string;
    limit_by_start_of_day?: boolean;
    show_until_last?: string;
    report_period?: ReportPeriod;
    stroke_curve?: LineChartStrokeType,
    tag_values?: string[];
    group_by_station?: boolean,
    group_by_user?: boolean,
    statistic_type?: StatisticType,
    duration_sample_point?: DurationSamplePoint,
    cumulative?: boolean,
    annotation_x?: GraphReportAnnotation[],
    annotation_y?: GraphReportAnnotation[],
    decimal_places?: number,
    custom_report?: string,
}

export interface GraphReportNamedModelOptions {
    filter_by_stations?: NamedModel[];
    filter_by_station_group?: StationGroup;
    duration_types?: DurationType[];
    duration_category?: EventCategory;
    instance_types?: InstanceType[];
    instance_category?: EventCategory;
    tag_series?: (NamedModel & HasColour)[];
    group_by_tag?: EventTagType;
}

export interface GraphReportModelIDOptions {
    filter_by_stations?: number[]
    filter_by_station_group?: number;
    duration_types?: number[];
    duration_category?: number;
    instance_types?: number[];
    instance_category?: number;
    tag_series?: number[];
    group_by_tag?: number;
}

export type GraphReportResponseOptions = GraphReportBaseOptions & GraphReportNamedModelOptions
export type GraphReportRequestOptions = GraphReportBaseOptions & GraphReportModelIDOptions

export interface GraphReportResponse {
    id: number;
    title: string;
    type: ReportType;
    options: GraphReportResponseOptions;
    refresh_period: string;
}

export interface GraphReportRequest {
    title: string;
    type: string;
    options: GraphReportRequestOptions;
    refresh_period: string;
}

export interface DashboardOptions {
    textSize?: ReportTextSize
}

export interface DashboardResponse extends NamedModel {
    widgets: WidgetData[]
    options?: DashboardOptions
}

export interface SharedDashboardResponse extends NamedModel {
    token: string,
    dashboard: number,
    dashboard_object?: DashboardResponse, // Present on get by token. Missing on list.
    created_on: string,
    created_by: number,
    url: string,
}

export type DataValue = number | string | [number | string, number | string]
export type Data<T> = {[key: string]: T}
export type DataArray<T> = {[key: string]: T[]}
export type DataGroup<T> = {[key: string]: {[key: string]: T}}

export interface GraphReportResults {
    // TODO: Complete typing for GraphReportResult.
    [key: string]: any,
    title: string,
    refreshSeconds: number,
    data: Data<DataValue> | DataArray<DataValue> | DataGroup<DataValue> | TableData
    graphReport: GraphReportResponse,
    isDuration: boolean,
    decimalPlaces: number,
}

export interface CustomReportManifest {
    name: string
    options: any
}

export type VariableDataType = 'TEXT' | 'NUMERIC' | 'DATETIME' | 'TIME' | 'DURATION'

export interface Variable {
    id: number,
    name: string,
    type: VariableDataType,
    text_value?: string,
    numeric_value?: number,
    datetime_value?: string,
    time_value?: string,
    duration_value?: string,
    last_updated: string,
    last_updated_by?: number,
    insights_managed: boolean,
}

export const START_OF_DAY = 'Start of Day'
export const START_OF_WEEK = 'Start of Week'
export const DEFAULT_DECIMAL_PLACES = 'Default Decimal Places'
export const DAYS_OF_THE_WEEK_SELECT_OPTIONS = [
    {label: 'Monday', value: 0},
    {label: 'Tuesday', value: 1},
    {label: 'Wednesday', value: 2},
    {label: 'Thursday', value: 3},
    {label: 'Friday', value: 4},
    {label: 'Saturday', value: 5},
    {label: 'Sunday', value: 6},
]
