/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Input, Row} from 'reactstrap';
import PageTitle from '../../../components/PageTitle';
import {PAGES} from '../../../constants/pages';
import {deleteOrGoLogin, getOrGoLogin, patchOrGoLogin, postOrGoLogin} from '../../../helpers/api';
import {API, DAYS_OF_THE_WEEK_SELECT_OPTIONS, START_OF_WEEK, Variable, VariableDataType} from '../../../constants/api';
import {toast} from 'react-toastify';
import {handleErrorAsToast} from '../../../helpers/errors';
import {DeleteModal} from '../../../components/DeleteModal';
import Select from '../../../components/Select';
import {getVariableValue, getVariableValueFieldName, partition} from '../../../helpers/common';
import classNames from 'classnames';

interface InputForVariableTypeProps {
    variable?: Variable,
    type: VariableDataType,
    value: any,
    onChange: (value: any) => void,
    [key: string]: any,
}

export function InputForVariableType({variable, type, value, onChange, ...rest}: InputForVariableTypeProps) {
    const common = {
        ...rest,
        value,
        required: true,
        onChange: (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)
    }
    if (variable?.name === START_OF_WEEK) {
        return (
            <Select
                isMulti={false}
                options={DAYS_OF_THE_WEEK_SELECT_OPTIONS}
                value={DAYS_OF_THE_WEEK_SELECT_OPTIONS[value]}
                // @ts-ignore
                onChange={({value}) => onChange(value)}
            />
        )
    }
    switch (type) {
        case 'NUMERIC':
            return <Input {...common} type='number'/>
        case 'TEXT':
            return <Input {...common} type='text'/>
        case 'DATETIME':
            return <Input {...common} type='datetime-local'/>
        case 'TIME':
            return <Input {...common} type='time'/>
        case 'DURATION':
            return <>
                <Input {...common} type='text' title='A duration in the format DD HH:MM:SS'/>
                <div className='text-muted font-italic'>
                    <div>Accepted format: DD HH:MM:SS.</div>
                    <div>e.g. 2 12:30:08 represents 2 days, 12 hours, 30 minutes, 8 seconds. Days is optional.</div>
                </div>
            </>
    }
}

type VariableValueUpdate = {type: VariableDataType, original: any, new: any}

function GeneralConfiguration() {
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [variables, setVariables] = useState<Variable[]>([])
    const [variableValues, setVariableValues] = useState<Record<number, VariableValueUpdate>>({})

    const [addingNewVariable, setAddingNewVariable] = useState(false)
    const [variableToDelete, setVariableToDelete] = useState<Variable | undefined>()

    const variableSortFunc = (a: Variable, b: Variable) => a.name.localeCompare(b.name)

    useEffect(() => {
        getOrGoLogin(API.VERSION.REPORTS.VARIABLES)
            .then(data => {
                data.sort(variableSortFunc)
                setVariables(data)
                setVariableValues(Object.fromEntries(
                    data.map(variable => [variable.id, {
                        type: variable.type,
                        original: getVariableValue(variable),
                        new: getVariableValue(variable),
                    }])
                ))
            }).catch(handleErrorAsToast).finally(() => setLoading(false))
    }, [])

    const createVariable = (name: string, type: VariableDataType, value: any) => {
        postOrGoLogin<Variable>(API.VERSION.REPORTS.VARIABLES.path, {
            name,
            type,
            [getVariableValueFieldName(type)]: value,
        }).then((response) => {
            const updatedVariables = [...variables, response].sort(variableSortFunc)
            setVariables(updatedVariables)
            setVariableValues(Object.fromEntries(
                updatedVariables.map(variable => [variable.id, {
                    type: variable.type,
                    original: getVariableValue(variable),
                    new: getVariableValue(variable),
                }])
            ))
            toast.success(`Created variable: '${response.name}'`)
            setAddingNewVariable(false)
        }).catch(handleErrorAsToast).finally(() => setLoading(false))
    }

    const updateVariableValue = (id: number, update: VariableValueUpdate) => {
        setLoading(true)
        patchOrGoLogin(API.VERSION.REPORTS.VARIABLES.VARIABLE.path.replace(':variableId', id.toString()), {
            [getVariableValueFieldName(update.type)]: update.new
        }).then((response: Variable) => {
            const index = variables.findIndex(it => it.id === response.id)
            if (index !== -1) {
                const updatedVariables = [...variables]
                updatedVariables[index] = response
                setVariables(updatedVariables)
                setVariableValues(prevState => ({
                    ...prevState,
                    [response.id]: {
                        type: response.type,
                        original: getVariableValue(response),
                        new: getVariableValue(response),
                    }
                }))
                toast.success(`Updated variable: '${response.name}'`)
            }
        }).catch(handleErrorAsToast).finally(() => setLoading(false))
    }

    const deleteVariable = (variable: Variable) => {
        setLoading(true)
        deleteOrGoLogin(API.VERSION.REPORTS.VARIABLES.VARIABLE.path.replace(':variableId', variable.id.toString()))
            .then(() => {
                const index = variables.findIndex(it => it.id === variableToDelete!.id)
                if (index !== -1) {
                    const updatedVariables = [...variables]
                    updatedVariables.splice(index, 1)
                    setVariables(updatedVariables)
                    setVariableValues(prevState => {
                        const newState = {...prevState}
                        delete newState[variableToDelete!.id]
                        return newState
                    })
                    setVariableToDelete(undefined)
                    toast.success(`Deleted variable: "${variable.name}"`)
                }
            })
            .catch(handleErrorAsToast).finally(() => setLoading(false))
    }

    const [configOptions, reportVariables] = useMemo(
        () => partition(variables, variable => variable.insights_managed),
        [variables]
    )

    const filteredReportVariables = useMemo(
        () => reportVariables.filter(variable =>
            variable.name.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        [reportVariables, searchQuery]
    )

    return (
        <>
            <PageTitle
                breadCrumbItems={[{label: PAGES.CONFIGURATION.GENERAL.displayName, active: true}]}
                title={PAGES.CONFIGURATION.GENERAL.displayName}
            />
            <Row>
                <Col md={6}>
                    <Card className='p-2'>
                        <CardHeader>
                            <div className='d-flex flex-row align-items-center mx-n1'>
                                <h4 className='text-nowrap mx-1'>Report Variables</h4>
                                <Input
                                    className='ml-auto mr-1 flex-fit'
                                    type='text'
                                    placeholder='Search...'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <Button
                                    block
                                    className='flex-fit text-nowrap'
                                    color='primary'
                                    disabled={loading || addingNewVariable}
                                    onClick={() => setAddingNewVariable(true)}>
                                    <i className='mdi mdi-plus-circle'/>
                                    <span className='ml-1 d-none d-lg-inline'>New Variable</span>
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <table className='table table-hover'>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th className='w-25'>
                                                Name
                                            </th>
                                            <th className='w-25'>
                                                Type
                                            </th>
                                            <th className='w-50'>
                                                Value
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredReportVariables.map((variable) => {
                                            const value = variableValues[variable.id]
                                            const valueIsSame = value.original === value.new
                                            return <tr key={variable.id}>
                                                <td>
                                                    {variable.name}
                                                </td>
                                                <td>
                                                    {variable.type}
                                                </td>
                                                <td>
                                                    <InputForVariableType
                                                        variable={variable}
                                                        type={variable.type}
                                                        value={value.new}
                                                        onChange={(value) => setVariableValues(prevState => {
                                                            return {
                                                                ...prevState,
                                                                [variable.id]: {...prevState[variable.id], new: value}
                                                            }
                                                        })}
                                                    />
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button
                                                            className='float-right'
                                                            color='primary'
                                                            onClick={() => updateVariableValue(variable.id, value)}
                                                            disabled={valueIsSame || loading}>
                                                            Save
                                                        </Button>
                                                        <Button
                                                            color='link'
                                                            title='Delete Variable'
                                                            className={classNames('text-danger btn-link-hover float-right')}
                                                            onClick={() => setVariableToDelete(variable)}>
                                                            <i className='mdi mdi-delete font-16'/>
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                        {addingNewVariable &&
                                            <NewVariableRow
                                                onSave={createVariable}
                                                onCancel={() => setAddingNewVariable(false)}
                                            />
                                        }
                                    </tbody>
                                </table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='p-2'>
                        <CardHeader>
                            <div className='d-flex flex-row align-items-center mx-n1'>
                                <h4 className='text-nowrap mx-1'>General Settings</h4>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <table className='table table-hover'>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th className='w-50'>
                                                Name
                                            </th>
                                            <th className='w-50'>
                                                Value
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {configOptions.map((config) => {
                                            const value = variableValues[config.id]
                                            const valueIsSame = value.original === value.new
                                            return (
                                                <tr key={config.id}>
                                                    <td>
                                                        {config.name}
                                                    </td>
                                                    <td>
                                                        <InputForVariableType
                                                            variable={config}
                                                            type={config.type}
                                                            value={value.new}
                                                            onChange={(value) => setVariableValues(prevState => {
                                                                return {
                                                                    ...prevState,
                                                                    [config.id]: {...prevState[config.id], new: value}
                                                                }
                                                            })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end'>
                                                            <Button
                                                                className='float-right'
                                                                color='primary'
                                                                onClick={() => updateVariableValue(config.id, value)}
                                                                disabled={valueIsSame || loading}>
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <DeleteModal
                isOpen={variableToDelete !== undefined}
                toggle={() => setVariableToDelete(undefined)}
                details={{
                    type: 'Variable',
                    name: variableToDelete?.name
                }}
                onDelete={() => deleteVariable(variableToDelete!)}>
                Deleting a variable could break any reports where it is being referenced.
            </DeleteModal>
        </>
    )
}

interface NewVariableRowProps {
    onSave: (name: string, type: VariableDataType, value: any) => void,
    onCancel: () => void
}

function NewVariableRow({onSave, onCancel}: NewVariableRowProps) {
    const [name, setName] = useState('')
    const [type, setType] = useState<VariableDataType>('TEXT')
    const [value, setValue] = useState<string>()

    const variableTypeOptions: {label: VariableDataType, value: VariableDataType}[] = [
        {label: 'NUMERIC', value: 'NUMERIC'},
        {label: 'TIME', value: 'TIME'},
        {label: 'DURATION', value: 'DURATION'},
        // {label: 'TEXT', value: 'TEXT'},
        // {label: 'DATETIME', value: 'DATETIME'},
    ]

    const okToSave = name.length > 0 && value !== undefined && value.length > 0

    return (
        <tr>
            <td>
                <Input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </td>
            <td>
                <Select
                    options={variableTypeOptions}
                    value={{label: type, value: type}}
                    onChange={(value) => value && setType(value.value)}
                />
            </td>
            <td>
                <InputForVariableType
                    type={type}
                    value={value}
                    onChange={setValue}
                />
            </td>
            <td>
                <div className='d-flex'>
                    <Button
                        color='secondary'
                        className='mr-1'
                        onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        disabled={!okToSave}
                        onClick={() => onSave(name, type, value)}>
                        Save
                    </Button>
                </div>
            </td>
        </tr>
    )
}

export default GeneralConfiguration
