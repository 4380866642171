/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import { OEEFactor } from './common';

export const HEX_COLOR_REGEX = RegExp('#[0-9a-fA-F]{6}', 'g')

export const colors = {
    'black-sand' : '#272f34',
    'action-blue': '#57c3f0',
    'steel-grey': '#6b727d',
}

/**
 * Dict of OEEFactor to Hexadecimal color value for representing OEE, Availability, Performance, and Quality.
 */
export const OEEColorDict: {[key in OEEFactor | 'OEE']: string} = {
    'OEE': '#00e396',
    'Availability': '#775dd0',
    'Performance': '#feb019',
    'Quality': '#ff4560'
}

/**
 * Retrieves array of Hexadecimal color values for representing (in order) OEE, Availability, Performance, and Quality.
 */
export function getOEEColorSeries() {
    return Object.values(OEEColorDict)
}

/**
 * Retrieves the representative value as declared by OEEColorSeries for the given OEE factor.
 * @param factor Factor to retrieve color for.
 */
export function getOEEColor(factor: OEEFactor | 'OEE') {
    return OEEColorDict[factor]
}
