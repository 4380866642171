import {
    CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_FAILED,
    FORGET_PASSWORD_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
} from './constants';
import {isUserAuthenticated} from '../../helpers/auth';

const INIT_STATE = {
    userAuthenticated: isUserAuthenticated(),
    loading: false,
};

interface AuthAction {type: string, payload: {}}
interface State {
    userAuthenticated? : boolean | null,
    loading? : boolean
    error?: string | null,
    success?: any,
}

const Auth = (state: State = INIT_STATE, action: AuthAction) => {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true};
        case LOGIN_USER_SUCCESS:
            return {...state, userAuthenticated: true, loading: false, error: null};
        case LOGIN_USER_FAILED:
            return {...state, error: action.payload, loading: false};
        case REGISTER_USER:
            return {...state, loading: true};
        case REGISTER_USER_SUCCESS:
            return {...state, user: action.payload, loading: false, error: null};
        case REGISTER_USER_FAILED:
            return {...state, error: action.payload, loading: false};
        case LOGOUT_USER:
            return {...state};
        case LOGOUT_USER_SUCCESS:
            return {...state, userAuthenticated: false};

        case FORGET_PASSWORD:
            return {...state, loading: true, error: null, success: null};
        case FORGET_PASSWORD_SUCCESS:
            return {...state, loading: false, error: null, success: action.payload};
        case FORGET_PASSWORD_FAILED:
            return {...state, loading: false, error: action.payload, success: null};

        case CHANGE_PASSWORD:
            return {...state, loading: true, error: null, success: null};
        case CHANGE_PASSWORD_SUCCESS:
            return {...state, loading: false, error: null, success: action.payload};
        case CHANGE_PASSWORD_FAILED:
            return {...state, loading: false, error: action.payload, success: null};
        default:
            return {...state};
    }
};

export default Auth;
