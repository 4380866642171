/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    INIT_USER_INFO,
    INIT_USER_INFO_FAILED,
    INIT_USER_INFO_SUCCESS,
    USER_UPDATE_DETAILS,
    USER_UPDATE_DETAILS_FAILED,
    USER_UPDATE_DETAILS_SUCCESS,
    USER_UPDATE_PASSWORD,
    USER_UPDATE_PASSWORD_FAILED,
    USER_UPDATE_PASSWORD_SUCCESS,
} from './constants';
import {UserAction, UserType} from './types';


export const userUpdateDetails = (firstName: string, lastName: string, email: string): UserAction => ({
    type: USER_UPDATE_DETAILS,
    payload: {firstName, lastName, email},
});

export const userUpdateDetailsSuccess = (firstName?: string, lastName?: string, email?: string): UserAction => ({
    type: USER_UPDATE_DETAILS_SUCCESS,
    payload: {firstName, lastName, email},
});

export const userUpdateDetailsFailed = (error: string): UserAction => ({
    type: USER_UPDATE_DETAILS_FAILED,
    payload: {error},
});

export const userUpdatePassword = (current_password: string, new_password: string): UserAction => ({
    type: USER_UPDATE_PASSWORD,
    payload: {current_password, new_password},
});

export const userUpdatePasswordSuccess = (message: string): UserAction => ({
    type: USER_UPDATE_PASSWORD_SUCCESS,
    payload: {message},
});

export const userUpdatePasswordFailed = (error: string): UserAction => ({
    type: USER_UPDATE_PASSWORD_FAILED,
    payload: {error},
});

export const initUserInfo = (): UserAction => ({
    type: INIT_USER_INFO,
    payload: {},
});

export const initUserInfoSuccess = (user: UserType): UserAction => ({
    type: INIT_USER_INFO_SUCCESS,
    payload: user,
});

export const userInfoFailed = (error: string): UserAction => ({
    type: INIT_USER_INFO_FAILED,
    payload: {error},
});