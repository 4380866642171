import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {APP_NAME} from '../constants/common';

type PageTitleProps = {
    breadCrumbItems: {active?: boolean, path?: string, label?: string}[]
    title: string,
    subtitle?: string,
};

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div className='page-title-box'>
                    <div className='page-title-right'>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to='/'>{APP_NAME}</Link>
                            </BreadcrumbItem>
                            {props.breadCrumbItems.map((item, index) => {
                                return item.active ? (
                                    <BreadcrumbItem active key={index}>
                                        {item.label}
                                    </BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem key={index}>
                                        <Link to={item.path ?? ''}>{item.label}</Link>
                                    </BreadcrumbItem>
                                );
                            })}
                        </Breadcrumb>
                    </div>
                    <div className='d-flex flex-row align-items-baseline'>
                        <h4 className='page-title'>{props.title}</h4>
                        { props.subtitle && <span className='ml-3 text-muted font-italic'>{props.subtitle}</span> }
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
