// @flow
import {UserType} from '../redux/user/types';

export const LOCAL_STORAGE_AUTH_KEY = 'auth';

const isUserAuthenticated = () => {
    return localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) === JSON.stringify(true);
};

const setAuthenticated = (value: boolean): void => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(value));
}

/**
 * Returns the full name, first name, or last name of the given user
 * if it exists, in that priority, otherwise returns the username/login.
 * @param user
 */
const tryGetUserFullName = (user: UserType): string => {
    if (user.first_name || user.last_name) return user.first_name + ' ' + user.last_name;
    else return user.username;
};

/**
 * Returns the full initials, first initials, or last initials
 * if it exists, in that priority, otherwise returns the username/login initials.
 * @param user
 */
const tryGetInitials = (user: UserType): string => {
    if (user.first_name || user.last_name)
        return user.first_name?.substring(0,1).toUpperCase() + '' + user.last_name?.substring(0,1).toUpperCase();
    else return user.username?.substring(0,1).toUpperCase();
};

export { isUserAuthenticated, setAuthenticated, tryGetUserFullName, tryGetInitials };
