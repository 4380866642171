/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {getOrGoLogin, patchOrGoLogin, postOrGoLogin} from '../../helpers/api';
import {API} from '../../constants/api';
import {
    initUserInfoSuccess,
    userInfoFailed,
    userUpdateDetailsFailed,
    userUpdateDetailsSuccess,
    userUpdatePasswordFailed,
    userUpdatePasswordSuccess
} from './actions';
import {ChangePasswordType, UserDetailsType, UserType} from './types';
import {INIT_USER_INFO, USER_UPDATE_DETAILS, USER_UPDATE_PASSWORD} from './constants';
import {handleErrorAsToast} from '../../helpers/errors';
import {toast} from 'react-toastify';

function* userUpdateDetails({payload: {first_name, last_name, email}}: UserDetailsType) {
    const body = {
        first_name: first_name,
        last_name: last_name,
        email,
    };

    try {
        const response: UserType = yield call(patchOrGoLogin, API.USER.PROFILE_INFO.path, body);
        toast.success('Success')
        yield put(userUpdateDetailsSuccess(response.first_name, response.last_name, response.email));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to save user settings')
        yield put(userUpdateDetailsFailed(error.statusText));
    }
}

function* userUpdatePassword({payload: {current_password, new_password}}: ChangePasswordType) {
    const body = {
        current_password,
        new_password,
    };

    try {
        const response: string = yield call(postOrGoLogin, API.USER.CHANGE_PASSWORD.path, body);
        toast.success('Password successfully updated')
        yield put(userUpdatePasswordSuccess(response));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to save user settings')
        yield put(userUpdatePasswordFailed(error.statusText));
    }
}

function* initUserInfo() {
    try {
        const response: UserType = yield call(getOrGoLogin, API.USER.PROFILE_INFO);
        yield put(initUserInfoSuccess(response));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to retrieve user info')
        yield put(userInfoFailed(error.statusText));
    }
}

export function* watchUserUpdateDetails(): any {
    // @ts-ignore
    yield takeEvery(USER_UPDATE_DETAILS, userUpdateDetails);
}

export function* watchUserUpdatePassword(): any {
    // @ts-ignore
    yield takeEvery(USER_UPDATE_PASSWORD, userUpdatePassword);
}

export function* watchUserInfoInit(): any {
    // @ts-ignore
    yield takeEvery(INIT_USER_INFO, initUserInfo);
}

function* userSaga(): any {
    yield all([fork(watchUserUpdatePassword), fork(watchUserInfoInit), fork(watchUserUpdateDetails)]);
}

export default userSaga;