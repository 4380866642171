import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import { PAGES } from '../constants/pages';

import {isUserAuthenticated} from '../helpers/auth';
import {associateBy} from '../helpers/common';

// Lazy load all the views
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const ChangePassword = React.lazy(() => import('../pages/auth/ChangePassword'));
const WelcomePage = React.lazy(() => import('../pages/dashboards/WelcomePage'));
const DashboardPage = React.lazy(() => import('../pages/dashboards/Dashboard'));
const CreateNewDashboard = React.lazy(() => import('../pages/dashboards/DashboardCreator'));
const ReportsPage = React.lazy(() => import('../pages/reports'));
const SearchPage = React.lazy(() => import('../pages/search/Search'));
const DeveloperPage = React.lazy(() => import('../pages/developer/index'));
const NotFoundPage = React.lazy(() => import('../pages/error/NotFound'));
const EventsConfigurationPage = React.lazy(() => import('../pages/configuration/event/EventConfiguration'));
const StationsConfigurationPage = React.lazy(() => import('../pages/configuration/station/StationsConfiguration'));
const UsersConfigurationPage = React.lazy(() => import('../pages/configuration/user/UsersConfiguration'));
const GeneralConfigurationPage = React.lazy(() => import('../pages/configuration/general/GeneralConfiguration'));
const UserSettings = React.lazy(() => import('../pages/account/UserSettings'));
const UserSetup = React.lazy(() => import('../pages/auth/UserSetup'));

// Handle auth and authorization
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: PAGES.ACCOUNTS.LOGIN.path, state: { from: props.location } }} />;
            }

            // const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            // TODO check user permissions
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{pathname: '/'}}/>;
            // }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// Root routes
const rootRoute = {
    path: '/',
    exact: true,
    route: PrivateRoute,
    component: function RedirectToDashboard() {
        const [redirect, setRedirect] = useState(undefined)
        useEffect(() => {
            waitForDashboardRoutes()
                // Redirect to the first dashboard route if any exist, otherwise redirect to the dashboard creator.
                .then(() => setRedirect(dashboardPageRoutes.children.length > 1 ?
                    (dashboardPageRoutes.children[1].path) : PAGES.WELCOME.path));
        }, [setRedirect])
        return redirect ? <Redirect to={redirect}/> : null
    },
};

// Auth
const authRoutes = {
    path: PAGES.ACCOUNTS.path,
    children: [
        {
            path: PAGES.ACCOUNTS.LOGIN.path,
            name: PAGES.ACCOUNTS.LOGIN.displayName,
            component: Login,
            route: Route,
        },
        {
            path: PAGES.ACCOUNTS.LOGOUT.path,
            name: PAGES.ACCOUNTS.LOGOUT.displayName,
            component: Logout,
            route: Route,
        },
        {
            path: PAGES.ACCOUNTS.FORGOT_PASSWORD.path,
            name: PAGES.ACCOUNTS.FORGOT_PASSWORD.displayName,
            component: ForgotPassword,
            route: Route,
        },
        {
            path: PAGES.ACCOUNTS.CHANGE_PASSWORD.path,
            name: PAGES.ACCOUNTS.CHANGE_PASSWORD.displayName,
            component: ChangePassword,
            route: Route,
        },
        {
            path: PAGES.ACCOUNTS.USER_SETUP.path,
            name: PAGES.ACCOUNTS.USER_SETUP.displayName,
            component: UserSetup,
            route: Route,
        },
    ],
};

// Dashboard routes
const dynamicDashboardRoutes = {
    path: PAGES.DASHBOARDS.path + ':id',
    route: PrivateRoute,
    component: DashboardPage,
};

const welcomePage = {
    path: PAGES.WELCOME.path,
    name: PAGES.WELCOME.displayName,
    route: PrivateRoute,
    component: WelcomePage,
}

const sharedDashboardRoutes = {
    path: PAGES.DASHBOARDS.SHARED.path + ':id',
    route: Route,
    component: DashboardPage,
}

const dashboardPageRoutes = {
    path: PAGES.DASHBOARDS.path,
    name: PAGES.DASHBOARDS.displayName,
    icon: PAGES.DASHBOARDS.icon,
    children: [{
        path: PAGES.CREATE_NEW_DASHBOARD.path,
        name: PAGES.CREATE_NEW_DASHBOARD.displayName,
        route: PrivateRoute,
        component: CreateNewDashboard,
        dashboardId: 0,
    }]
};

let dashboardPageRoutesPopulated = false;

async function waitForDashboardRoutes() {
    while (!dashboardPageRoutesPopulated) {
        await new Promise(resolve => {
            setTimeout(function() {
                resolve();
            }, 500)
        });
    }
}

export function populateDashboardPageRoutes(data) {
    let routes = dashboardPageRoutes.children;
    for (let i = 0; i < data.length; i++) {
        routes.push({
            route: PrivateRoute,
            component: DashboardPage,
            header: 'Apps',
            path: PAGES.DASHBOARDS.path + data[i].id + '/',
            name: data[i].name ? data[i].name : 'Unnamed Dashboard (' + data[i].id + ')',
            dashboardId: data[i].id,
        });
    }
    // Associate routes by id to remove duplicates when this method is called more than once.
    routes = Object.values(associateBy(routes, it => it.dashboardId))
        .sort((a, b) => a.dashboardId - b.dashboardId)

    dashboardPageRoutes.children = routes;
    dashboardPageRoutesPopulated = true;
}

export function addDashboardRoute(id, name) {
    let routes = dashboardPageRoutes.children;
    routes.push({
        route: PrivateRoute,
        component: DashboardPage,
        header: 'Apps',
        path: PAGES.DASHBOARDS.path + id + '/',
        name: name ? name : 'Unnamed Dashboard (' + id + ')',
        dashboardId: id,
    });
    routes.sort((a, b) => a.dashboardId > b.dashboardId)
    dashboardPageRoutes.children = routes;
}

export function updateDashboardRoute(id, name) {
    id = JSON.parse(id);
    let routes = dashboardPageRoutes.children;
    const index = routes.findIndex(dashboardRoute => dashboardRoute.dashboardId === id);
    routes[index] = {...routes[index], name: name ? name : 'Unnamed Dashboard (' + id + ')'};
    routes.sort((a, b) => a.dashboardId > b.dashboardId)
    dashboardPageRoutes.children = routes;
}

export function removeDashboardRoute(id) {
    id = JSON.parse(id);
    let routes = dashboardPageRoutes.children;
    routes.splice(routes.findIndex(dashboardRoute => dashboardRoute.dashboardId === id), 1);
    routes.sort((a, b) => a.dashboardId > b.dashboardId)
    dashboardPageRoutes.children = routes;
}

const reportsPageRoutes = {
    path: PAGES.REPORTS.path,
    name: PAGES.REPORTS.displayName,
    route: PrivateRoute,
    icon: PAGES.REPORTS.icon,
    component: ReportsPage,
    header: 'Apps',
};

const searchPageRoutes = {
    path: PAGES.SEARCH.path,
    name: PAGES.SEARCH.displayName,
    route: PrivateRoute,
    icon: PAGES.SEARCH.icon,
    component: SearchPage,
    header: 'Apps',
};

const configurationPageRoutes = {
    path: PAGES.CONFIGURATION.path,
    name: PAGES.CONFIGURATION.displayName,
    route: PrivateRoute,
    icon: PAGES.CONFIGURATION.icon,
    header: 'Configuration',
    children: [{
        path: PAGES.CONFIGURATION.GENERAL.path,
        name: PAGES.CONFIGURATION.GENERAL.displayName,
        route: PrivateRoute,
        component: GeneralConfigurationPage,
    }, {
        path: PAGES.CONFIGURATION.EVENTS.path,
        name: PAGES.CONFIGURATION.EVENTS.displayName,
        route: PrivateRoute,
        component: EventsConfigurationPage,
    }, {
        path: PAGES.CONFIGURATION.STATIONS.path,
        name: PAGES.CONFIGURATION.STATIONS.displayName,
        route: PrivateRoute,
        component: StationsConfigurationPage,
    }, {
        path: PAGES.CONFIGURATION.USERS.path,
        name: PAGES.CONFIGURATION.USERS.displayName,
        route: PrivateRoute,
        component: UsersConfigurationPage,
    }, {
        path: PAGES.CONFIGURATION.ADMIN.path,
        name: PAGES.CONFIGURATION.ADMIN.displayName,
        route: PrivateRoute,
    }]
}

const userProfileRoute = {
    path: PAGES.ACCOUNTS.USER_SETTINGS.path,
    name: PAGES.ACCOUNTS.USER_SETTINGS.displayName,
    route: PrivateRoute,
    component: UserSettings
}

const apiPageRoutes = {
    path: PAGES.API.path, // Redirects to Django API docs
    name: PAGES.API.displayName,
    icon: PAGES.API.icon,
    route: PrivateRoute,
}

const menuRoutes = [
    dashboardPageRoutes,
    reportsPageRoutes,
    searchPageRoutes,
    configurationPageRoutes,
    apiPageRoutes,
];

// Hidden routes
const hiddenRoutes = [
    {
        path: PAGES.DEVELOPER.path,
        name: PAGES.DEVELOPER.displayName,
        component: DeveloperPage,
        route: PrivateRoute,
    },
];

const notFoundRoute = {
    path: '',
    name: '404 Not Found',
    component: NotFoundPage,
    route: PrivateRoute,
};

// Flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const allRoutes = [
    rootRoute,
    authRoutes,
    welcomePage,
    sharedDashboardRoutes,
    dynamicDashboardRoutes,
    ...menuRoutes,
    ...hiddenRoutes,
    userProfileRoute,

    // Keep `notFoundRoute` at end to match all routes last.
    notFoundRoute
];

const allFlattenRoutes = flattenRoutes(allRoutes);
export {allRoutes, menuRoutes, allFlattenRoutes};
