/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import { prependPath } from './api';
import {APP_NAME} from './common';

export interface PageType {
    icon?: string,
    displayName: string,
    path: string
}

export const PAGES = {
    path: '/',
    ACCOUNTS: {
        path: 'accounts',
        LOGIN: {
            displayName: 'Login',
            path: 'login'
        },
        LOGOUT: {
            icon: 'mdi mdi-exit-to-app',
            displayName: 'Logout',
            path: 'logout'
        },
        FORGOT_PASSWORD: {
            displayName: 'Forgot Password',
            path: 'forgot-password'
        },
        CHANGE_PASSWORD: {
            displayName: 'Change Password',
            path: 'change-password'
        },
        USER_SETTINGS: {
            icon: 'mdi mdi-account-outline',
            displayName: 'User Settings',
            path: 'user-settings',
        },
        USER_SETUP: {
            displayName: 'User Setup',
            path: 'user-setup'
        }
    },
    WELCOME: {
        displayName: `Welcome to ${APP_NAME}`,
        path: 'welcome',
    },
    DASHBOARDS: {
        icon: 'mdi mdi-chart-box-outline',
        displayName: 'Dashboards',
        path: 'dashboards',
        SHARED: {path: 'shared'}
    },
    CREATE_NEW_DASHBOARD: {
        displayName: 'Create New Dashboard',
        path: 'create-new-dashboard',
    },
    REPORTS: {
        icon: 'mdi mdi-chart-line',
        displayName : 'Reports',
        path: 'reports',
    },
    SEARCH: {
        icon: 'mdi mdi-magnify',
        displayName: 'Search',
        path: 'search'
    },
    CONFIGURATION: {
        icon: 'mdi mdi-cog-outline',
        displayName: 'Configuration',
        path: 'configuration',
        GENERAL: {
            displayName: 'General Configuration',
            path: 'general',
        },
        EVENTS: {
            displayName: 'Event Configuration',
            path: 'events',
        },
        STATIONS: {
            displayName: 'Station Configuration',
            path: 'stations',
        },
        USERS: {
            displayName: 'User Management',
            path: 'users',
        },
        ADMIN: {
            displayName: 'Admin',
            path: 'admin',
        },
    },
    API: {
        icon: 'mdi mdi-code-tags',
        displayName: 'API',
        path: 'api',
    },
    DEVELOPER: {
        displayName: 'Developer',
        path: 'developer'
    },
    ERROR_PAGE: {
        displayName: 'Error',
        path: 'error',
    },
    init: function() {
        prependPath(this, this.path);
        this.CONFIGURATION.ADMIN.path = '/admin'
        return this;
    }
}.init();

export const INSIGHT = 'https://myinsights.nz'

export const MOTION_DESIGN = 'https://motiondesign.nz'
