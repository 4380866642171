// @flow
import { all } from 'redux-saga/effects';
import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import configSaga from './config/saga';
import layoutSaga from './layout/saga';
import userSaga from './user/saga';

export default function* rootSaga(): any {
    yield all([appMenuSaga(), authSaga(), configSaga(), layoutSaga(), userSaga()]);
}
