/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import column from '../assets/images/chart-thumbnails/column.png';
import line from '../assets/images/chart-thumbnails/line.png';
import pie from '../assets/images/chart-thumbnails/pie.png';
import text from '../assets/images/chart-thumbnails/text.png';
import radialBar from '../assets/images/chart-thumbnails/radialBar.png';
import InsightSymbol from '../assets/images/chart-thumbnails/insights-raster.png';
import {GraphCalculationType, ReportType} from './api';

// Minimum of 2 ticks so timescale is discernible.
export const MINIMUN_NUM_OF_XAXIS_TICKS = 2

export function getGraphReportTypeImage(graphReportType: ReportType): string {
    switch (graphReportType.category) {
        case 'Comparison': return column
        case 'Distribution': return pie
        case 'Trend': return line
        case 'Percentage': return text
        case 'Gauge': return radialBar
        case 'Tabular': return InsightSymbol
        default: return InsightSymbol
    }
}

export const GraphColourOptions = [
    {value: null, label: 'None'},
    {value: true, label: 'Red Low / Green High'},
    {value: false, label: 'Green Low / Red High'},
]

export const GraphCalculationTypeOptions: {value: GraphCalculationType, label: string}[] = [
    {value: 'ADDITION', label: 'Addition (+)'},
    {value: 'SUBTRACTION', label: 'Subtraction (-)'},
]
