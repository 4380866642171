/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {
    INIT_USER_INFO,
    INIT_USER_INFO_FAILED,
    INIT_USER_INFO_SUCCESS,
    USER_UPDATE_PASSWORD,
    USER_UPDATE_PASSWORD_FAILED,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_DETAILS,
    USER_UPDATE_DETAILS_SUCCESS,
    USER_UPDATE_DETAILS_FAILED,
} from './constants';
import {State, UserAction} from './types';

const INIT_STATE = {
    error: null,
    message: null,
    loading: false,
};

const User = (state: State = INIT_STATE, action: UserAction) => {
    switch (action.type) {
        case INIT_USER_INFO:
            return action.payload;
        case INIT_USER_INFO_SUCCESS:
            return action.payload;
        case INIT_USER_INFO_FAILED:
            return {...state, error: action.payload};
        case USER_UPDATE_DETAILS:
            return {...state, loading: true};
        case USER_UPDATE_DETAILS_SUCCESS:
            return {...state, error: null, loading: false, ...action.payload};
        case USER_UPDATE_DETAILS_FAILED:
            return {...state, loading: false, ...action.payload};
        case USER_UPDATE_PASSWORD:
            return {...state, loading: true};
        case USER_UPDATE_PASSWORD_SUCCESS:
            return {...state, error: null, loading: false, ...action.payload};
        case USER_UPDATE_PASSWORD_FAILED:
            return {...state, message: null, loading: false, ...action.payload};
        default:
            return {...state};
    }
};

export default User;